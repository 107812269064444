export default function HsvpBlog() {
    
    const handleLocationSuccess = () => {
        // Push the event to the dataLayer
        window.dataLayer.push({
            event: 'mapsOpenSuccess',
            officeLocation: 'success'
        })}

    return ( 
    <>
        <section className="container mx-auto lg:max-w-4xl md:max-w-2xl pb-[40px] m-blogs">
            <div>
            <nav className="w-full font-montserrat mt-[32px]">
                <ol className="list-reset flex max-md:text-[14px]">
                    <li>
                    <a href="/" className="text-primary transition duration-150 ease-in-out hover:text-[#13293d] focus:text-[#13293d] active:text-[#60a5fa]">Home</a>
                    </li>
                    <li>
                    <span className="mx-2 text-black">/</span>
                    </li>
                    <li>
                    <a href="/#blog" className="text-primary transition duration-150 ease-in-out hover:text-[#13293d] focus:text-[#13293d] active:text-[#60a5fa]">Blogs</a>
                    </li>
                    <li>
                    <span className="mx-2 text-black">/</span>
                    </li>
                    <li className="text-black">Hsvp Auction</li>
                </ol>
            </nav>
            </div>

            <section className="max-md:mx-[10px] max-sm:mx-[0px]">
                <div className="flex flex-col">
                    <div className="md:text-[36px] text-[24px] font-spartan pt-[20px] leading-tight text-[#13293d]">Why Investing in HSVP Auction Plots Could Be Your Best Decision Yet!</div>
                    <hr className="my-[5px]"/>
                    
                    {/* <div className="flex gap-2 font-montserrat max-md:text-[14px]">
                        <span><span style={{fontWeight:"700"}}>Author -</span> Apoorva Raj |</span>
                        <span>5 Min Read</span>
                    </div> */}
                </div>

                <div className="overflow-hidden object-cover md:h-[400px] h-[200px] object-center my-[10px] lg:rounded-lg">
                    <img src="https://ik.imagekit.io/AcmeSpaces/hsvp.jpeg" alt="hsvp img" className="h-full w-full overflow-hidden object-cover"/>
                </div>
                <br/>

                <div>
                    <section className="flex-col flex font-montserrat text-left gap-[2px]">
                    {/* <div className="md:text-[24px] text-[20px] font-semibold">Introduction:</div> */}
                        {/* <ul className="md:text-[22px] text-[16px]">
                            <li className="list-outside list-disc">Are you looking to invest in real estate that promises both security and potential for high returns?</li>
                        </ul> */}
                        <div className="md:text-[18px] text-[16px]">
                        <strong className="text-[#13293d]">Haryana Shehri Vikas Pradhikaran (HSVP) </strong>offers an opportunity for investors to acquire residential, commercial, and industrial properties through its official portal. 
                        <br/><br/>
                        Here's why you should consider investing:
                        </div>
                        <br/>
                        <ul className="md:text-[18px] text-[16px]">
                                <div className="list-decimal">
                                    <li className="font-bold text-[#13293d]">Official Portal:</li>
                                    <p className="">HSVP's official portal is the gateway to acquiring upcoming properties in Haryana. Whether you're eyeing <strong className="text-[#13293d]">residential plots</strong> for your home, <strong className="text-[#13293d]">commercial properties</strong> for business ventures, or <strong className="text-[#13293d]">industrial plots</strong> for expansion, the portal provides a transparent platform for all.</p>
                                </div>
                                
                                <br/>

                                <div className="list-decimal">
                                    <li className="font-bold text-[#13293d]">Easy Participation:</li>
                                    <p className="">Registering for the auction is online and free! <br/> Also, if you're not the lucky allottee in the auction, your Earnest Money Deposit (EMD) is refunded.
                                    </p>
                                </div>

                                <br/>

                                <div className="list-decimal">
                                    <li className="font-bold text-[#13293d]">Convenient Payment Plans:</li>
                                    <p className="">HSVP offers convenient payment plans making it easier for investors to manage their finances:</p>
                                    <br/>
                                    <table class="table-auto lg:w-3/4 w-full">
                                        <thead>
                                            <tr>
                                                <th className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 bg-[lightgray] max-sm:text-[12px] text-[#13293d]">Payment Due From Day Of Allotment</th>
                                                <th className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 bg-[lightgray] max-sm:text-[12px] text-[#13293d]">10 Days</th>
                                                <th className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 bg-[lightgray] max-sm:text-[12px] text-[#13293d]">45 Days</th>
                                                <th className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 bg-[lightgray] max-sm:text-[12px] text-[#13293d]">150 Days</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">Amount</td>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">10%</td>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">15%</td>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">75%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                
                                <br/>
                                <div className="list-decimal">
                                    <li className="font-bold text-[#13293d]">Loans from Leading Banks:</li>
                                    <p className="">Loans are available from all leading brands like ICICI, HDFC and SBI etc.</p>                                
                                </div>
                        </ul>

                        <br/>
                        {/* <div className="md:text-[22px] text-[20px] font-semibold">Conclusion:</div> */}
                        <div className="md:text-[18px] text-[16px]">
                        <a target="_blank" rel='noreferrer' href="https://wa.link/6n3hp9" className="underline text-[#13293d] font-bold">Get in touch with us today</a> in case you need:
                         <ul className="list-image-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjMzhiZGY4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy42ODUuMTUzYS43NTIuNzUyIDAgMCAxIC4xNDMgMS4wNTJsLTggMTAuNWEuNzUuNzUgMCAwIDEtMS4xMjcuMDc1bC00LjUtNC41YS43NS43NSAwIDAgMSAxLjA2LTEuMDZsMy44OTQgMy44OTMgNy40OC05LjgxN2EuNzUuNzUgMCAwIDEgMS4wNS0uMTQzWiIgLz48L3N2Zz4=')]">
                            <li>Assistance regarding how to apply in the auction.</li>
                            <li>Advice to buy the right property in auction.</li>
                            <li>To Apply for a bank loan for your property.</li>
                         </ul>
                        </div>
                        
                        <br/>
                        <div className="md:text-[22px] text-[20px] font-semibold">Contact Info:</div>
                        <div className="md:text-[18px] text-[16px] flex flex-col">
                        <span className="text-left">Phone: <a target="_blank" rel='noreferrer' href="tel:+919910109700" className="underline">9910109700</a></span>
                        <span className="text-left">Location: <a target="_blank" rel='noreferrer' onClick={handleLocationSuccess} id="WS_Office_HSVP_blog" href="https://maps.app.goo.gl/aijdNdgpSzWqxm4o6" className="underline">SCO-61, OMAXE WORLD STREET, Sector 79, Faridabad, Haryana 121004</a></span>
                        </div>

                    </section>
                </div>
            </section>

        </section>
    </> 
    )};