import '../App.css'
import Blogs from '../components/homepage/BlogCards'
import Founders from '../components/homepage/Founders'
import HeroSlider from '../components/homepage/HeroSection'
// import LatestListings from '../components/homepage/ListingCards'
import Offices from '../components/homepage/OfficeCards'
import OurValues from '../components/homepage/OurValues'
import BrandPartners from '../components/homepage/PartnersCarousel'
import Services from '../components/homepage/Services'

export default function Home() {
    return (
        <>
            <HeroSlider />
            <OurValues />
            <Services />
            {/* <LatestListings /> */}
            <Founders />
            <Blogs />
            <BrandPartners />
            <Offices /> 
        </>
    )
}