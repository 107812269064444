export default function PageNoteFound() {

    return(
        <>
        <div className="bg404">
            <div className="flex flex-col container mx-auto h-[80vh]">
                <div class="text-center text-[#13293d] sm:text-[200px] text-[150px] font-extrabold font-HK max-sm:mt-[6rem]">404</div>
                <div class="text-center text-black xl:text-7xl md:text-6xl sm:text-5xl text-xl font-semibold font-HK -mt-[2rem]">OOOPS ! PAGE NOT FOUND</div>
                <div class="text-center text-black md:text-[1.5rem] sm:text-[1rem] text-[0.75rem] mt-[1rem] font-normal font-HK">Sorry the page you are looking for does not exist, if you think something is broken, write us to report the issue!</div>

                <div className="flex max-md:flex-col max-md:items-center justify-center sm:gap-[4rem] gap-[2.5rem] mt-[2rem]">
                    <a href="/" className="w-[150px] text-center rounded-[9.38px] bg-[#13293d] font-hk text-white p-[8px] sm:text-base text-xs font-medium">Return Home</a>
                    <a href="mailto:pranjamehta37@acmesapces.com" className="w-[150px] text-center border border-[#13293d] rounded-[9.59px] p-[8px] sm:text-base text-xs font-medium font-hk">Report Problem</a>

                </div>
            </div>
        </div>
        </>

)}