import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Navbar from './utilities/Temp-nav';
import Footer from './utilities/Temp-footer';
import CustomSVG from './assets/svg/CustomSvg';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Navbar />
      <App />
      <Footer />
      <a target="_blank" rel='noreferrer' href="https://api.whatsapp.com/send?phone=919910360601&text=Hi%20Acme%20Spaces%20team!%0APlease%20connect%20me%20to%20a%20sales%20executive." className='container mx-auto whatsapp-ico'>
        <CustomSVG iconName="WhatsappStickyIcon"/>
      </a>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
