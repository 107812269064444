function Careers() {
    return ( 
        <>
        <section className="relative mb-[4em]">
        <img src="https://ik.imagekit.io/AcmeSpaces/careersbg.jpg" style={{filter:"brightness(0.5)"}} class="block 2xl:w-[100vw] aspect-[144/41] max-lg:w-full object-cover object-left md:h-[40vh] md:max-lg:h-[290px] h-[200px] lg:w-full" alt="..."/>
        <div className="absolute top-0 md:h-[40vh] md:max-lg:h-[290px] h-[200px] w-full">
            <div className="h-full text-center flex justify-center items-center md:text-[5rem] sm:text-[64px] text-[42px] text-white font-spartan shadown-xl">Careers</div>
        </div>

        <div className="sm:!mx-auto mx-[20px] sm:container xl:max-w-7xl sm:w-full font-montserrat my-[2em] max-xl:mx-[2em] m-auto max-xl:flex">
            <div className="max-xl:w-full max-sm:hidden">
                <h1 className="text-4xl font-spartan">Open Positions</h1>
                <div className="border border-gray-500 my-[2em] py-[0.5em] px-[2em] pb-[20px]">
                    <div className="flex justify-between  mt-[20px]">
                        <h2 className="text-2xl">Marketing Executive</h2>
                        <a href="mailto:sales@acmespaces.com" className="leading-0 py-[8px] px-[22px] bg-[#13293d] text-white contact-btn-css" style={{borderRadius:"0px"}}>Apply</a>
                    </div>
                    <div>Faridabad, Haryana</div>
                    <div className="mt-[32px]">
                        <div className="text-xl font-spartan">Job Qualification:</div>
                        <ul>
                        <li>Graduate in marketing or similar field.</li>
                        <li>Experience in Sales Preferred.</li>
                        </ul>
                        <div className="text-xl mt-[12px] font-spartan">Payout:</div>
                        <ul>
                        <li>Rs. 15,000 to 20,000</li>
                        <li>Sales based Incentives.</li>
                        <li></li>
                        </ul>

                        <div className="text-xl mt-[12px] font-spartan">How To Apply:</div>
                        <div>Mail to sales@acmespaces.com with Subject Line “Application for Marketing Executive” with your resume & anything else you would want to share with us. Also, mention why you want to apply for the position, your motivation towards work along with your college and degree name in the email.</div>
                    </div>
                </div>
            </div>

            <div className="sm:hidden w-[100%]">
                <div className="font-spartan text-[24px]">Open Positions</div>
                <div className="border border-gray-500 h-auto bg-[#f8f8f8] p-[14px]">
                    <div className="mb-[12px] flex justify-between">
                        <div className="">
                            <h2 className="font-spartan text-[20px]">Marketing Executive</h2>
                            <div className="text-[14px]">Faridabad, Haryana</div>
                        </div>
                        <a href="mailto:sales@acmespaces.com" className="bg-[#13293d] text-white contact-btn-css" style={{borderRadius:"0px", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"14px", height:"26px", padding:"14px 12px", marginTop:"4px"}}>
                            <span>Apply</span>
                        </a>
                    </div>
                    <div className="font-spartan text-[20px]">
                    Job Qualification:
                    </div>
                    <div className="flex flex-col mb-[12px]">
                        <span className="text-[14px]">Graduate in marketing or similar field.</span>
                        <span className="text-[14px]">Experience in Sales Preferred.</span>
                    </div>
                    <div className="flex flex-col mb-[12px]">
                        <span className="font-spartan text-[20px]">Payout:</span>
                        <span className="text-[14px]">Rs. 15,000 to 20,000</span>
                        <span className="text-[14px]">Sales based Incentives.</span>
                    </div>
                    <div className="flex flex-col">
                        <div className="font-spartan text-[20px]">How To Apply:</div>
                        <div className="text-[14px]">Mail to sales@acmespaces.com with Subject Line “Application for Marketing Executive”.</div> 
                    </div>
                </div>
            </div>
        </div>
        </section>
        </>
     );
}

export default Careers;