export default function PrivacyPolicy() {
    return(
        <>
        <header class="bg-[#6495ed] text-white py-4">
        <h1 class="text-3xl font-bold text-center">Privacy Policy</h1>
        </header>

        <main class="container mx-auto sm:px-4 py-8">
            <div className="w-full lg:px-[10em] md:text-justify text-left">
                <section class="mb-8">
                    <h2 class="text-2xl font-semibold mb-4 font-spartan">Introduction:</h2>
                    <p className="font-montserrat">Welcome to Acme Spaces! This privacy policy explains how we collect, use, and protect your personal information when you interact with our website or utilize our services. By accessing our website or providing your information to us, you consent to the terms outlined in this policy.</p>
                </section>

                <section class="mb-8">
                    <h2 class="text-2xl font-semibold mb-4 font-spartan">Information Collected:</h2>
                    <p className="font-montserrat">We may collect the following types of personal information from you:</p>
                    <ul class="list-disc pl-8 font-montserrat">
                        <li>Full name</li>
                        <li>Phone number</li>
                        <li>Address, State, Province, ZIP/Postal code, City</li>
                        <li>Email Addresses</li>


                    </ul>
                </section>

                <section class="mb-8">
                    <h2 class="text-2xl font-semibold mb-4 font-spartan">Purpose of Collection:</h2>
                    <p className="font-montserrat">We collect this information for the following purposes:</p>
                    <ul class="list-disc pl-8 font-montserrat">
                        <li>Facilitating property inquiries and purchases</li>
                        <li>Providing personalized property recommendations</li>
                        <li>Enhancing customer service and support</li>
                        <li>Communicating important updates and promotions</li>
                    </ul>
                </section>

                <section class="mb-8">
                    <h2 class="text-2xl font-semibold mb-4 font-spartan">How Information is Used:</h2>
                    <p className="font-montserrat">The information we collect is used for the following purposes:</p>
                    <ul class="list-disc pl-8 font-montserrat">
                        <li>Processing property inquiries and transactions</li>
                        <li>Contacting customers regarding their inquiries or purchases</li>
                        <li>Personalizing user experiences based on preferences and interests</li>
                        <li>Improving our services and offerings based on user feedback</li>
                    </ul>
                </section>

                <section class="mb-8">
                    <h2 class="text-2xl font-semibold mb-4 font-spartan">Data Sharing:</h2>
                    <p className="font-montserrat">We do not share your personal information with third parties unless required by law or with your explicit consent. Your privacy is of utmost importance to us, and we take all necessary measures to protect your information.</p>
                </section>

                <section class="mb-8">
                    <h2 class="text-2xl font-semibold mb-4 font-spartan">Data Security:</h2>
                    <p className="font-montserrat">We employ industry-standard security measures to safeguard your personal information from unauthorized access, disclosure, alteration, or destruction. This includes encryption, secure servers, and regular security audits.</p>
                </section>

                <section class="mb-8">
                    <h2 class="text-2xl font-semibold mb-4 font-spartan">User Rights:</h2>
                    <p className="font-montserrat">You have the right to access, update, or delete your personal information at any time. If you wish to exercise these rights or have any concerns about your privacy, please contact us using the information provided below.</p>
                </section>

                <section class="mb-8">
                    <h2 class="text-2xl font-semibold mb-4 font-spartan">Updates to the Privacy Policy:</h2>
                    <p className="font-montserrat">We reserve the right to update this privacy policy as needed to reflect changes in our practices or legal requirements. Any updates will be posted on this page, and the date of the last update will be revised accordingly.</p>
                </section>

                <section class="mb-8">
                    <h2 class="text-2xl font-semibold mb-4 font-spartan">Contact Information:</h2>
                    <p className="font-montserrat">If you have any questions, comments, or concerns about our privacy practices or this policy, please contact us at:</p>
                    <ul class="list-disc pl-8">
                        <li>Email: <a href="mailto:your@email.com" class="text-blue-500">sales@acmespaces.com</a></li>
                        <li>Address: Acme Spaces Pvt. Ltd. SCO-61,GROUND FLOOR, OMAXE WORLD STREET, Sector 79, Faridabad, Haryana 121004</li>
                    </ul>
                </section>
            </div>

        </main>
        </>
)}
