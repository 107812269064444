import React, {useEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CustomSVG from "../../assets/svg/CustomSvg";
import AOS from 'aos';
import 'aos/dist/aos.css';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        // style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      ><CustomSVG iconName="slickNextArrow"/>
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        // style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      ><CustomSVG iconName="slickPrevArrow"/></div>
    );
  }

const foundersData = [
    { 
        name: "CA Rajinder Mehta",
        imgSrc : "https://ik.imagekit.io/AcmeSpaces/founderImgs/Rajinder%20Mehta%20Director.png",
        designation: "Director",
    },
    { 
        name: "Mr. Ramesh Mehta",
        imgSrc : "https://ik.imagekit.io/AcmeSpaces/founderImgs/Ramesh%20Mehta%20Director.png",
        designation: "Director",
    },
    { 
        name: "Mr. Divij Mehta",
        imgSrc : "https://ik.imagekit.io/AcmeSpaces/founderImgs/Divij%20Mehta%20Director.png",
        designation: "Director",
    }
]

const getAnchorPlacement = () => {
    if (window.innerWidth < 768) {
      return "top-bottom";
    } else {
      return "center-bottom";
    }
  }

export default function Founders() {
    useEffect(() => {
        AOS.init();
        }, [])
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
      };
    return(
        <>
        <div className="sm:container mx-auto">
            <div  data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement={getAnchorPlacement()} className="flex items-center justify-center md:mt-[100px] md:mb-[70px] sm:mt-[55px] sm:mb-[40px] mt-[70px] mb-[30px]">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="458" height="2" viewBox="0 0 458 2" className="max-2xl:w-[300px] max-xl:w-[260px] max-lg:w-[120px] max-md:w-[110px] max-sm:hidden" fill="none">
                    <path d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1 2H458V0L1 0V2Z" fill="#D9D9D9"/>
                    </svg>
                </div>
                    <div className="text-black text-5xl font-spartan font-semibold mx-[14px] max-md:text-[40px] max-sm:text-[28px]">Our Founders</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="458" height="2" viewBox="0 0 458 2" className="max-2xl:w-[300px] max-xl:w-[260px] max-lg:w-[120px] max-md:w-[110px] max-sm:hidden" fill="none">
                    <path d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1 2H458V0L1 0V2Z" fill="#D9D9D9"/>
                    </svg>
                </div>
            </div>

        <div className="justify-center grid grid-cols-3">
            <div data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement={getAnchorPlacement()} className="max-md:hidden lg:grid lg:justify-end flex-col flex justify-center">
                <div className="flex justify-center">
                    <img className="object-cover object-center lg:w-[200px] lg:h-[200px] sm:w-[150px] sm:h-[150px] w-[90px] h-[90px] rounded-[150px] border-8 border-[#6495ED] border-opacity-60" alt="foundersimg" src="https://ik.imagekit.io/AcmeSpaces/founderImgs/Rajinder%20Mehta%20Director.png" />
                </div>
                <div className="text-[#13293D] lg:text-3xl md:text-[26px] sm:text-[24px] text-[12px] font-semibold font-spartan flex justify-center lg:mt-[18px] mt-[14px] whitespace-nowrap">CA Rajinder Mehta</div>
                <div className="text-[#6495ED] lg:text-[26px] md:text-[22px] sm:text-[18px] max-sm:text-[14px] font-semibold font-montserrat flex justify-center">Director</div>
            </div>
            
            <div data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement={getAnchorPlacement()} className="max-md:hidden flex-col flex justify-center">
                <div className="flex justify-center">
                    <img className="object-cover object-center lg:w-[200px] lg:h-[200px] sm:w-[150px] sm:h-[150px] w-[90px] h-[90px] rounded-[150px] border-8 border-[#6495ED] border-opacity-60" alt="foundersimg" src="https://ik.imagekit.io/AcmeSpaces/founderImgs/Ramesh%20Mehta%20Director.png?updatedAt=1705866772537" />
                </div>
                <div className="text-[#13293D] lg:text-3xl md:text-[26px] sm:text-[24px] text-[12px] font-semibold font-spartan flex justify-center lg:mt-[18px] mt-[14px] whitespace-nowrap">Mr. Ramesh Mehta</div>
                <div className="text-[#6495ED] lg:text-[26px] md:text-[22px] sm:text-[18px] max-sm:text-[14px] font-semibold font-montserrat flex justify-center">Director</div>
            </div>


            <div data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement={getAnchorPlacement()} className="max-md:hidden lg:grid lg:justify-start flex-col flex justify-center">
                <div className="flex justify-center">
                    <img className="object-cover object-center lg:w-[200px] lg:h-[200px] sm:w-[150px] sm:h-[150px] w-[90px] h-[90px] rounded-[150px] border-8 border-[#6495ED] border-opacity-60" alt="foundersimg" src="https://ik.imagekit.io/AcmeSpaces/founderImgs/Divij%20Mehta%20Director.png" />
                </div>
                <div className="text-[#13293D] lg:text-3xl md:text-[26px] sm:text-[24px] text-[12px] font-semibold font-spartan flex justify-center lg:mt-[18px] mt-[14px] whitespace-nowrap">Mr. Divij Mehta</div>
                <div className="text-[#6495ED] lg:text-[26px] md:text-[22px] sm:text-[18px] max-sm:text-[14px] font-semibold font-montserrat flex justify-center">Director</div>
            </div>
        </div>

        <div data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement={getAnchorPlacement()} className="md:hidden sm:w-[360px] w-[260px] flex justify-center mx-auto">
            <Slider {...settings}
            className="container mx-auto">
            {foundersData.map((d) => (
                <div className="md:hidden !block !w-max ms-auto me-auto">
                <div className="lg:grid lg:justify-end flex-col flex justify-center">
                    <div className="flex justify-center">
                        <img className="object-cover object-center lg:w-[200px] lg:h-[200px] sm:w-[200px] sm:h-[200px] w-[150px] h-[150px] rounded-[150px] border-8 border-[#6495ED] border-opacity-60" src={d.imgSrc} alt="foundersimg" />
                    </div>
                    <div className="text-[#13293D] sm:text-[32px] text-[22px] font-semibold font-spartan flex justify-center lg:mt-[18px] mt-[14px] whitespace-nowrap">{d.name}</div>
                    <div className="text-[#6495ED] sm:text-[28px] text-[20px] font-semibold font-montserrat flex justify-center  mb-[1rem]">{d.designation}</div>
                </div>
                </div>
            ))}
            </Slider>
        </div>

        </div>

        </>

)}