import React from "react";
import {
  TEModal,
} from "tw-elements-react";

function NewsletterPopup({showModal, setShowModal }) {
  return (
    <div>
      {/* Modal */}
      <TEModal show={showModal} setShow={setShowModal} className="flex flex-col items-center justify-center max-md:pb-[90px]">
          
          
        <div style={{border:"solid #8F2924 3px"}} className="container text-black sm-newsletter-modal w-[500px] sm:h-[320px] h-[250px] bg-white rounded-[5%] shadow flex-col justify-start inline-flex justify-center">
          <div className="flex flex-col items-center -mt-[1em]">
            <div>
              <img className="w-[160px]" src="https://ik.imagekit.io/AcmeSpaces/nav-logo.png" alt="acme-logo"/>
            </div>
            <p className="sm:mb-[0.5em] font-HK text-[22px] font-bold leading-[46px]">Thank you for subscribing</p>
            {/* <p className="font-HK text-[14px]">We've got you all confirmed, thank you for subscribing.</p> */}
          </div>
          <div className="flex mb-[-2em] h-[20%] items-end justify-center">
            <button style={{borderRadius:"16px"}} className="mt-4 !pt-[4px] newsletter-btn text-[18px] btn-red" onClick={() => setShowModal(false)}>
              close
            </button>
          </div>
        </div>




      </TEModal>
    </div>
  );
}

export default NewsletterPopup;
