import React from "react";
import { TECarousel, TECarouselItem } from "tw-elements-react";
import CustomSVG from "../../assets/svg/CustomSvg";

const handleChatSuccess = () => {
  // Push the event to the dataLayer
  window.dataLayer.push({
      event: 'chatstartedSuccess',
      whatsAppChat: 'success'
  })}

export default function HeroSlider(){
  return (
    <>
      <TECarousel
        className="z-[1]"
        showControls
        showIndicators
        crossfade
        ride="carousel"
        prevBtnIcon={
          <>
            <span className="inline-block max-lg:hidden text-black h-8 w-8 [&>svg]:h-8">
            <CustomSVG iconName="heroPrevBtnIcon" />
            </span>
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden max-lg:hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Previous
            </span>
          </>
        }
        nextBtnIcon={
          <>
            <span className="inline-block max-lg:hidden text-black h-8 w-8 [&>svg]:h-8">
            <CustomSVG iconName="heroNextBtnIcon" />
            </span>
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden max-lg:hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Next
            </span>
          </>
        }
        theme={{
          indicator:
            "mx-1 max-lg:h-[8px] max-lg:w-[8px] w-[10px] h-[10px] bg-[#737272] opacity-50 rounded-full cursor-pointer opacity-50 hover:opacity-100 transition-opacity duration-600 ease-in-out relative top-[40px]"  
            /*mx-[3px]  box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-black bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none*/,
        }}
      >
        <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
          <TECarouselItem
            itemID={1}
            className="relative float-left -mr-[100%] hidden w-full !transform-none transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
          >
          
          <img
            // src="https://ik.imagekit.io/AcmeSpaces/heroslider1.png"
            src="https://ik.imagekit.io/AcmeSpaces/builderfloor-bg.jpg"
            className="block 2xl:w-[100vw] aspect-[144/41] max-lg:w-full object-cover object-left max-lg:h-[290px] lg:w-full"
            alt="..."
          />
          <div className="max-sm:left-[23px] bottom-[120px] max-2xl:bottom-[80px] max-xl:bottom-[45px] max-lg:bottom-[40px] max-sm:bottom-[80px] 
          text-left text-white  font-HK absolute inset-x-[15%] py-5 md:block">
            <span className="text-[65px] max-2xl:text-[50px] max-xl:text-[40px] max-lg:text-[35px] max-md:text-[30px] max-sm:text-[25px] 
            font-bold leading-[74.42px] max-lg:leading-[4rem] max-sm:leading-[2.5rem] max-2xl:leading-[50px] span1-builder whitespace-nowrap overflow-hidden">LOOKING TO BUY A {""}</span><br/>
            <span className="text-[87px] text-[#13293d] max-2xl:text-[75px] max-xl:text-[70px] max-lg:text-[60px] max-md:text-[55px] max-sm:text-[47px] 
            font-bold leading-[99.61px] max-lg:leading-10 max-xl:leading-[80px] span2-builder whitespace-nowrap overflow-hidden">BUILDER FLOOR?
            </span>

            <div className="pt-[50px] max-lg:pt-[25px] max-sm:pt-[40px]"><a id="HeroBuilderFloors" onClick={handleChatSuccess} target="_blank" rel="noreferrer" href="https://wa.link/skbvpf" className="btn-type1">View Latest Listings</a></div>
          </div>
            
          </TECarouselItem>
          <TECarouselItem
            itemID={2}
            className="relative float-left -mr-[100%] hidden w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
          >
            <img
              src="https://ik.imagekit.io/AcmeSpaces/heroslider2.png"
              className="block 2xl:w-[100vw] aspect-[144/41] max-lg:w-full object-cover object-bottom max-lg:h-[290px] lg:w-full"
              alt="..."
            />
            <div className="max-sm:left-[23px] bottom-[120px] max-2xl:bottom-[80px] max-xl:bottom-[45px] max-lg:bottom-[40px] max-sm:bottom-[40px] 
            !flex items-center flex-col text-white font-HK absolute inset-x-[15%] hidden py-5 md:block max-sm:ml-[1rem]">
              <span className=" text-[65px] max-2xl:text-[50px] max-xl:text-[40px] max-lg:text-[35px] max-md:text-[30px] max-sm:text-[25px] 
              font-bold leading-[74.42px] max-lg:leading-[2.5rem] max-2xl:leading-[50px] span1-residential whitespace-nowrap overflow-hidden">LOOKING <span className="text-[#13293D]">TO BUY </span>A {""}</span>
              <span className=" text-[87px] max-2xl:text-[75px] max-xl:text-[70px] max-lg:text-[60px] max-md:text-[55px] max-sm:text-[47px] 
            font-bold leading-[99.61px] max-lg:leading-[54px] max-sm:leading-[40px] max-xl:leading-[80px] span2-residential max-sm:text-center sm:whitespace-nowrap overflow-hidden">RESIDENTIAL PROPERTY? 
              </span>

              <div className="max-sm:pt-[40px] max-lg:pt-[36px] pt-[50px]"><a id="HeroResidential" onClick={handleChatSuccess} target="_blank" rel="noreferrer" href="https://wa.link/f0l2vg" className="btn-type1">View Projects</a></div>
            </div>
          </TECarouselItem>
          <TECarouselItem
            itemID={3}
            className="relative float-left -mr-[100%] hidden w-full !transform-none transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
          >
            <img
              src="https://ik.imagekit.io/AcmeSpaces/commercial%201_LG14AoCOB.png?updatedAt=1705568344886"
              // src="https://ik.imagekit.io/AcmeSpaces/heroslider3.png"
              className="block 2xl:w-[100vw] aspect-[144/41] max-lg:w-full object-cover object-left max-lg:h-[290px] lg:w-full"
              alt="..."
            />
            <div className="max-xl:right-[110px] max-sm:right-[23px] bottom-[120px] max-2xl:bottom-[80px] max-xl:bottom-[45px] max-lg:bottom-[20px] max-sm:bottom-[62px]
            !flex sm:items-end flex-col text-white font-HK absolute inset-x-[15%] hidden py-5 md:block max-sm:ml-[-1.7rem]">
              <span className=" text-[65px] max-2xl:text-[50px] max-xl:text-[40px] max-lg:text-[35px] max-md:text-[30px] max-sm:text-[24px] 
              font-bold leading-[74.42px] max-lg:leading-[2rem] max-2xl:leading-[50px] whitespace-nowrap overflow-hidden
              ">LOOKING TO <span className="text-[#13293D]">INVEST IN </span>A {""}</span>
              <span className=" text-[87px] max-2xl:text-[75px] max-xl:text-[70px] max-lg:text-[60px] max-md:text-[55px] max-sm:text-[36px] 
              -bold leading-[99.61px] max-lg:leading-[54px]  max-sm:leading-[34px] max-xl:leading-[80px] lg:whitespace-nowrap lg:overflow-hidden max-lg:text-right max-sm:text-left 
              "><span className="text-white">COMMERCIAL </span>PROPERTY?
              </span>

              <div className="pt-[50px] max-lg:pt-[25px] max-sm:pt-[34px]"><a id="HeroCommercial" onClick={handleChatSuccess} target="_blank" rel="noreferrer" href="https://wa.link/cpy29n" className="btn-type1">View Projects</a></div>
            </div>
          </TECarouselItem>
        </div>
      </TECarousel>
    </>
  );
}