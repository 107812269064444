import React from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

export default function SellYourPoroperty() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const toastifySuccess = () => {
    toast.success("Form sent!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      onOpen: () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "formSubmission",
          formStatus: "success",
        });
      },
    });
  };

  const onSubmit = async (data) => {
      try {
        const submitButton = document.getElementById('syp-form');
        submitButton.classList.add('hidden');

        const submitButtonShow = document.getElementById('sbmtBtnShow');
        submitButtonShow.classList.add('!flex');

          const response = await fetch("https://api.acmespaces.com/api/v1/sellproperty", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
    
          if (response.ok) {
            reset();
            toastifySuccess();
            const submitButtonShow = document.getElementById('sbmtBtnShow');
            submitButtonShow.classList.add('hidden');
            submitButtonShow.classList.remove('!flex');


            const submitButton = document.getElementById('syp-form');
            submitButton.classList.add('block');
            submitButton.classList.remove('hidden');
          } else {
            throw new Error("Failed to send email");
          }
        } catch (error) {
          console.error(error);
          toast.error("Failed to send email", {
            position: "bottom-right",
            onOpen: () => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "formSubmission",
                formStatus: "error",
              });
            },
          });
        }
      };
      const validatePhoneNumber = (value) => {
        const phoneNumberRegex = /^\d{10}$/;
        return phoneNumberRegex.test(value) || "Please enter a valid 10-digit phone number";
      };

    return (
    <>
    <div className="container mx-auto max-w-7xl md:py-8 lg:py-[20px]">
      <div className="container mx-auto">
          
        </div>
        <div className="flex max-lg:flex-col max-lg:items-center mt-[20px] justify-center">
            <div className="xl:w-2/3 sm:w-1/2 font-montserrat leading-[normal] p-[20px] flex flex-col md:mt-[2em]">
                <div className="font-spartan xl:text-[4em] lg:text-[3em] md:text-[2.5em] text-[28px]">Are you interested in selling your property?</div>
                <div className="">
                <div className="xl:text-lg md:text-[16px] text-[14px] mt-[20px] lg:p-[8px]">Fill out the form, and someone from our team will reach out to you soon.<br/><span className="max-lg:hidden">For any queries or assistance with listing, reach out to us.</span>
                </div>
                {/* <li className="text-base p-[8px]"></li> */}
                </div>
                <div className="max-lg:hidden gap-[16px] items-center md:mt-[8px] mt-[22px] md:ml-[12px]">
                  <div className="md:mb-8 mb-[22px] flex w-full max-w-[370px]">
                    <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-[#6495ed] sm:h-[70px] sm:max-w-[70px]">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_941_17577)">
                          <path
                            d="M24.3 31.1499C22.95 31.1499 21.4 30.7999 19.7 30.1499C16.3 28.7999 12.55 26.1999 9.19997 22.8499C5.84997 19.4999 3.24997 15.7499 1.89997 12.2999C0.39997 8.59994 0.54997 5.54994 2.29997 3.84994C2.34997 3.79994 2.44997 3.74994 2.49997 3.69994L6.69997 1.19994C7.74997 0.599942 9.09997 0.899942 9.79997 1.89994L12.75 6.29994C13.45 7.34994 13.15 8.74994 12.15 9.44994L10.35 10.6999C11.65 12.7999 15.35 17.9499 21.25 21.6499L22.35 20.0499C23.2 18.8499 24.55 18.4999 25.65 19.2499L30.05 22.1999C31.05 22.8999 31.35 24.2499 30.75 25.2999L28.25 29.4999C28.2 29.5999 28.15 29.6499 28.1 29.6999C27.2 30.6499 25.9 31.1499 24.3 31.1499ZM3.79997 5.54994C2.84997 6.59994 2.89997 8.74994 3.99997 11.4999C5.24997 14.6499 7.64997 18.0999 10.8 21.2499C13.9 24.3499 17.4 26.7499 20.5 27.9999C23.2 29.0999 25.35 29.1499 26.45 28.1999L28.85 24.0999C28.85 24.0499 28.85 24.0499 28.85 23.9999L24.45 21.0499C24.45 21.0499 24.35 21.0999 24.25 21.2499L23.15 22.8499C22.45 23.8499 21.1 24.1499 20.1 23.4999C13.8 19.5999 9.89997 14.1499 8.49997 11.9499C7.84997 10.8999 8.09997 9.54994 9.09997 8.84994L10.9 7.59994V7.54994L7.94997 3.14994C7.94997 3.09994 7.89997 3.09994 7.84997 3.14994L3.79997 5.54994Z"
                            fill="currentColor"
                          />
                          <path
                            d="M29.3 14.25C28.7 14.25 28.25 13.8 28.2 13.2C27.8 8.15003 23.65 4.10003 18.55 3.75003C17.95 3.70003 17.45 3.20003 17.5 2.55003C17.55 1.95003 18.05 1.45003 18.7 1.50003C24.9 1.90003 29.95 6.80003 30.45 13C30.5 13.6 30.05 14.15 29.4 14.2C29.4 14.25 29.35 14.25 29.3 14.25Z"
                            fill="currentColor"
                          />
                          <path
                            d="M24.35 14.7002C23.8 14.7002 23.3 14.3002 23.25 13.7002C22.95 11.0002 20.85 8.90018 18.15 8.55018C17.55 8.50018 17.1 7.90018 17.15 7.30018C17.2 6.70018 17.8 6.25018 18.4 6.30018C22.15 6.75018 25.05 9.65018 25.5 13.4002C25.55 14.0002 25.15 14.5502 24.5 14.6502C24.4 14.7002 24.35 14.7002 24.35 14.7002Z"
                            fill="currentColor"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_941_17577">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="w-full">
                      <h4 className="mb-1 text-xl font-bold text-dark font-spartan">
                        Phone Number
                      </h4>
                      <a href="tel:+919910109700" className="underline underline-offset-2 text-base text-body-color font-montserrat">
                          +919910109700
                      </a>
                    </div>
                  </div>

                  <div className="mb-8 flex w-full max-w-[370px]">
                    <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-[#6495ed] sm:h-[70px] sm:max-w-[70px]">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M28 4.7998H3.99998C2.29998 4.7998 0.849976 6.1998 0.849976 7.9498V24.1498C0.849976 25.8498 2.24998 27.2998 3.99998 27.2998H28C29.7 27.2998 31.15 25.8998 31.15 24.1498V7.8998C31.15 6.1998 29.7 4.7998 28 4.7998ZM28 7.0498C28.05 7.0498 28.1 7.0498 28.15 7.0498L16 14.8498L3.84998 7.0498C3.89998 7.0498 3.94998 7.0498 3.99998 7.0498H28ZM28 24.9498H3.99998C3.49998 24.9498 3.09998 24.5498 3.09998 24.0498V9.2498L14.8 16.7498C15.15 16.9998 15.55 17.0998 15.95 17.0998C16.35 17.0998 16.75 16.9998 17.1 16.7498L28.8 9.2498V24.0998C28.9 24.5998 28.5 24.9498 28 24.9498Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                    <div className="w-full">
                      <h4 className="mb-1 text-xl font-bold text-dark font-spartan">
                        Email Address
                      </h4>
                      <a href="mailto:sales@acmespaces.com" className="underline underline-offset-2 text-base text-body-color font-montserrat">
                        sales@acmespaces.com
                      </a>
                    </div>
                  </div>                      
                </div>
            </div>
            
            <div className="max-sm:px-[20px] w-full xl:w-1/3 sm:w-[420px] ">
              <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col font-montserrat gap-[12px] p-[20px] max-sm:mt-[0px] text-lg rounded-lg shadow-[3px_3px_14px_#0000001a]'>

                  <div className="max-lg:hidden font-montserrat text-center text-xl pb-2">Fill the form below</div>
                  
                    <div className=""><span className="font-spartan text-base">Name</span>
                      <div className="relative">
                        <input type="text" style={{borderBottom:"1px solid rgba(0, 0, 0, 0.2)"}} className="relative appearance-none bg-transparent border-none w-full text-gray-700 mr-3  leading-tight focus:outline-none max-sm:text-[14px]" name="name"
                          {...register("name", {
                              required: {
                                value: true,
                                message: "Name Required*",
                              }
                            })}/>
                            {errors.name && (
                                <span className="errorMessage">
                                  {errors.name.message}
                                </span>
                              )}
                      </div>
                    </div>
                  
                  
                  
                  <div className=""><span className="font-spartan text-base">Email</span>
                    <div className="relative">
                      <input  style={{borderBottom:"1px solid rgba(0, 0, 0, 0.2)"}} type="text" className="relative appearance-none bg-transparent border-none w-full text-gray-700 mr-3  leading-tight focus:outline-none max-sm:text-[14px]" name="email"
                      {...register("email", {
                          required: true,
                          pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]{3,}\.[a-zA-Z0-9-]+/
                        })}/>
                        {errors.email && (
                          <span className="errorMessage">
                            Please enter a valid email address*
                          </span>
                      )}
                    </div>
                  </div>

                  <div className=""><span className="font-spartan text-base">Phone</span>
                    <div className="relative">
                      <input  style={{borderBottom:"1px solid rgba(0, 0, 0, 0.2)"}} type="number" className="relative appearance-none bg-transparent border-none w-full text-gray-700 mr-3  leading-tight focus:outline-none max-sm:text-[14px]" name="phone"
                      {...register("phone", {
                          required: {
                            value: true,
                            message: "Please enter a valid Number*",
                          },
                          maxLength: {
                            value: 10,
                            message:
                              "phone cannot exceed 10 characters",
                          },
                          validate: validatePhoneNumber
                        })}
                      />
                      {errors.phone && (
                          <span className="errorMessage">
                            {errors.phone.message}
                          </span>
                        )}

                    </div>
                  </div>
                  


                  <div className=""><span className="font-spartan text-base">Property Type</span>
                    <div className="relative">
                      <select  style={{borderBottom:"1px solid rgba(0, 0, 0, 0.2)"}} type="" className="relative bg-transparent border-none w-full text-gray-700 mr-3  leading-tight focus:outline-none max-sm:text-[14px]"
                      {...register("property", {
                        required: {
                          value: true,
                          message: "Please Select a Property Type*",
                        }
                      })}
                      >
                        <option disabled value="">Select an option</option>
                        <option className="" value="Residential">Residential</option>
                        <option className="" value="Commercial">Commercial</option>
                        <option className="" value="Land/Plot">Land/Plot</option>
                          {errors.property && errors.property.type === "required" && (
                            <span className="errorMessage">{errors.property.message}</span>
                          )}
                      </select>
                    </div>  
                  </div>                
                  <div className=""><span className="font-spartan text-base">Property Details</span>
                      <div className="relative">
                        <textarea rows="3"  style={{borderBottom:"1px solid rgba(0, 0, 0, 0.2)"}} type="text" className="relative appearance-none bg-transparent border-none w-full text-gray-700 mr-3  leading-tight focus:outline-none max-sm:text-[14px]" name="details"
                        {...register("details", {
                          required: {
                            value: true,
                            message: "Details about your property*",
                          },
                          minLength: {
                            value: 15,
                            message:
                              "Detail too short*",
                          },
                        })}
                        />
                        {errors.details && (
                            <span className="errorMessage">
                              {errors.details.message}
                            </span>
                          )}
                      </div>
                  </div>

                  <button type="submit" id="syp-form" className="mt-[12px] w-full rounded border border-primary bg-primary p-3 text-white transition hover:bg-opacity-90">Submit
                  </button>

                  <button type="submit" id="sbmtBtnShow" className="hidden items-center justify-center w-full rounded border border-primary bg-primary p-3 text-white transition hover:bg-opacity-90">Submit
                  <svg class="animate-spin -mr-1 sm:ml-3 ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  </button>
              </form>
            </div>


            <div className="lg:hidden xl:w-2/3 sm:w-1/2 font-montserrat leading-[normal] p-[20px] flex flex-col">
                <div className="">
                <div className="xl:text-lg md:text-[16px] text-[14px] mt-[0px] lg:p-[8px]"><span className="lg:hidden">For any queries or assistance with listing, reach out to us.</span>
                </div>
                {/* <li className="text-base p-[8px]"></li> */}
                </div>
                <div className="lg:hidden gap-[16px] items-center lg:mt-[8px] mt-[22px] lg:ml-[12px]">
                  <div className="md:mb-8 mb-[22px] flex w-full max-w-[370px]">
                    <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-[#6495ed] sm:h-[70px] sm:max-w-[70px]">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_941_17577)">
                          <path
                            d="M24.3 31.1499C22.95 31.1499 21.4 30.7999 19.7 30.1499C16.3 28.7999 12.55 26.1999 9.19997 22.8499C5.84997 19.4999 3.24997 15.7499 1.89997 12.2999C0.39997 8.59994 0.54997 5.54994 2.29997 3.84994C2.34997 3.79994 2.44997 3.74994 2.49997 3.69994L6.69997 1.19994C7.74997 0.599942 9.09997 0.899942 9.79997 1.89994L12.75 6.29994C13.45 7.34994 13.15 8.74994 12.15 9.44994L10.35 10.6999C11.65 12.7999 15.35 17.9499 21.25 21.6499L22.35 20.0499C23.2 18.8499 24.55 18.4999 25.65 19.2499L30.05 22.1999C31.05 22.8999 31.35 24.2499 30.75 25.2999L28.25 29.4999C28.2 29.5999 28.15 29.6499 28.1 29.6999C27.2 30.6499 25.9 31.1499 24.3 31.1499ZM3.79997 5.54994C2.84997 6.59994 2.89997 8.74994 3.99997 11.4999C5.24997 14.6499 7.64997 18.0999 10.8 21.2499C13.9 24.3499 17.4 26.7499 20.5 27.9999C23.2 29.0999 25.35 29.1499 26.45 28.1999L28.85 24.0999C28.85 24.0499 28.85 24.0499 28.85 23.9999L24.45 21.0499C24.45 21.0499 24.35 21.0999 24.25 21.2499L23.15 22.8499C22.45 23.8499 21.1 24.1499 20.1 23.4999C13.8 19.5999 9.89997 14.1499 8.49997 11.9499C7.84997 10.8999 8.09997 9.54994 9.09997 8.84994L10.9 7.59994V7.54994L7.94997 3.14994C7.94997 3.09994 7.89997 3.09994 7.84997 3.14994L3.79997 5.54994Z"
                            fill="currentColor"
                          />
                          <path
                            d="M29.3 14.25C28.7 14.25 28.25 13.8 28.2 13.2C27.8 8.15003 23.65 4.10003 18.55 3.75003C17.95 3.70003 17.45 3.20003 17.5 2.55003C17.55 1.95003 18.05 1.45003 18.7 1.50003C24.9 1.90003 29.95 6.80003 30.45 13C30.5 13.6 30.05 14.15 29.4 14.2C29.4 14.25 29.35 14.25 29.3 14.25Z"
                            fill="currentColor"
                          />
                          <path
                            d="M24.35 14.7002C23.8 14.7002 23.3 14.3002 23.25 13.7002C22.95 11.0002 20.85 8.90018 18.15 8.55018C17.55 8.50018 17.1 7.90018 17.15 7.30018C17.2 6.70018 17.8 6.25018 18.4 6.30018C22.15 6.75018 25.05 9.65018 25.5 13.4002C25.55 14.0002 25.15 14.5502 24.5 14.6502C24.4 14.7002 24.35 14.7002 24.35 14.7002Z"
                            fill="currentColor"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_941_17577">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="w-full">
                      <h4 className="mb-1 text-xl font-bold text-dark font-spartan">
                        Phone Number
                      </h4>
                      <a href="tel:+919910109700" className="underline underline-offset-2 text-base text-body-color font-montserrat">
                          +919910109700
                      </a>
                    </div>
                  </div>

                  <div className="mb-8 flex w-full max-w-[370px]">
                    <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-[#6495ed] sm:h-[70px] sm:max-w-[70px]">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M28 4.7998H3.99998C2.29998 4.7998 0.849976 6.1998 0.849976 7.9498V24.1498C0.849976 25.8498 2.24998 27.2998 3.99998 27.2998H28C29.7 27.2998 31.15 25.8998 31.15 24.1498V7.8998C31.15 6.1998 29.7 4.7998 28 4.7998ZM28 7.0498C28.05 7.0498 28.1 7.0498 28.15 7.0498L16 14.8498L3.84998 7.0498C3.89998 7.0498 3.94998 7.0498 3.99998 7.0498H28ZM28 24.9498H3.99998C3.49998 24.9498 3.09998 24.5498 3.09998 24.0498V9.2498L14.8 16.7498C15.15 16.9998 15.55 17.0998 15.95 17.0998C16.35 17.0998 16.75 16.9998 17.1 16.7498L28.8 9.2498V24.0998C28.9 24.5998 28.5 24.9498 28 24.9498Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                    <div className="w-full">
                      <h4 className="mb-1 text-xl font-bold text-dark font-spartan">
                        Email Address
                      </h4>
                      <a href="mailto:sales@acmespaces.com" className="underline underline-offset-2 text-base text-body-color font-montserrat">
                        sales@acmespaces.com
                      </a>
                    </div>
                  </div>                      
                </div>
            </div>            

        </div>
        <ToastContainer/>
    </div>
    </>
)}