import React from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// import Offices from "../components/homepage/OfficeCards";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CustomSVG from "../assets/svg/CustomSvg";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      // style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    ><CustomSVG iconName="slickNextArrow"/></div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      // style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    ><CustomSVG iconName="slickPrevArrow"/></div>
  );
}

const sliderData = [
  {
      img : "https://ik.imagekit.io/AcmeSpaces/offices/World%20street%20office.png",
      span1: "World Street",
      span2: "office",
      mapLink: "https://maps.app.goo.gl/aijdNdgpSzWqxm4o6"
  },
  {
      img : "https://ik.imagekit.io/AcmeSpaces/offices/Puri%20High%20street%20Office.png",
      span1: "Puri High Street",
      span2: "office",
      mapLink: "https://maps.app.goo.gl/i3JboDnpQsZAiBKP8"
  },
  {
      img : "https://ik.imagekit.io/AcmeSpaces/offices/Omaxe%20Hills%20and%20Forest%20spa%20office.png",
      span1: "Omaxe Hills and",
      span2: "Forest Spa office",
      mapLink: "https://maps.app.goo.gl/esYfHvQkGiDwnarP6"
  }
]

const Contact = () => {
      
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
    
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };

    // Function that displays a success toast on bottom right of the page when form submission is successful
      const toastifySuccess = () => {
        toast.success("Form sent!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          onOpen: () => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "contactFormSubmission",
              contactFormSubmission: "success",
            });
          },
        });
      };
    
    const onSubmit = async (data) => {
        try {
          const submitButton = document.getElementById('sbmtBtn');
          submitButton.classList.add('hidden');
  
          const submitButtonShow = document.getElementById('sbmtBtnShow');
          submitButtonShow.classList.add('!flex');

            const response = await fetch("https://api.acmespaces.com/api/v1/sendmail", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ templateParams: data }),
            });
      
            if (response.ok) {
              reset();
              toastifySuccess();
              const submitButtonShow = document.getElementById('sbmtBtnShow');
              submitButtonShow.classList.add('hidden');
              submitButtonShow.classList.remove('!flex');
  
  
              const submitButton = document.getElementById('sbmtBtn');
              submitButton.classList.add('block');
              submitButton.classList.remove('hidden');
            } else {
              throw new Error("Failed to send email");
            }
          } catch (error) {
            console.error(error);
            toast.error("Failed to send email", {
              position: "bottom-right",
              onOpen: () => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "contactFormSubmission",
                  contactFormSubmission: "error",
                });
              },
            });
          }
        };

        const validatePhoneNumber = (value) => {
          const phoneNumberRegex = /^\d{10}$/;
          return phoneNumberRegex.test(value) || "Please enter a valid 10-digit phone number";
        };
  
        const handleLocationSuccess = () => {
          // Push the event to the dataLayer
          window.dataLayer.push({
              event: 'mapsOpenSuccess',
              officeLocation: 'success'
          })}

  return (
    <>
      <section className="overflow-hidden bg-white py-8 lg:py-[20px] md:-mb-[60px] sm:-mb-[25px] -mb-[40px]">
        <div className="container mx-auto 2xl:max-w-7xl 2xl:pl-[12px] 2xl:pr-[12px]">
          <div className="-mx-4 flex flex-wrap lg:justify-between">
            <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
              <div className="mb-12 max-w-[570px] lg:mb-0 ">
                <span className="mb-4 block text-base font-semibold text-[#6495ed]">
                  {/* Contact Us */}
                </span>
                <h2 className="mb-6 text-[32px] font-bold uppercase text-dark sm:text-[40px] lg:text-[36px] xl:text-[40px] font-spartan">
                  Contact Us
                </h2>
                <p className="mb-9 text-base leading-relaxed text-body-color font-montserrat">
                    For any real estate information or queries, please feel free to connect with us using the provided form.
                </p>
                <div className="mb-8 flex w-full max-w-[370px]">
                  <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-[#6495ed] sm:h-[70px] sm:max-w-[70px]">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.6 11.8002L17.7 3.5002C16.65 2.8502 15.3 2.8502 14.3 3.5002L1.39998 11.8002C0.899983 12.1502 0.749983 12.8502 1.04998 13.3502C1.39998 13.8502 2.09998 14.0002 2.59998 13.7002L3.44998 13.1502V25.8002C3.44998 27.5502 4.84998 28.9502 6.59998 28.9502H25.4C27.15 28.9502 28.55 27.5502 28.55 25.8002V13.1502L29.4 13.7002C29.6 13.8002 29.8 13.9002 30 13.9002C30.35 13.9002 30.75 13.7002 30.95 13.4002C31.3 12.8502 31.15 12.1502 30.6 11.8002ZM13.35 26.7502V18.5002C13.35 18.0002 13.75 17.6002 14.25 17.6002H17.75C18.25 17.6002 18.65 18.0002 18.65 18.5002V26.7502H13.35ZM26.3 25.8002C26.3 26.3002 25.9 26.7002 25.4 26.7002H20.9V18.5002C20.9 16.8002 19.5 15.4002 17.8 15.4002H14.3C12.6 15.4002 11.2 16.8002 11.2 18.5002V26.7502H6.69998C6.19998 26.7502 5.79998 26.3502 5.79998 25.8502V11.7002L15.5 5.4002C15.8 5.2002 16.2 5.2002 16.5 5.4002L26.3 11.7002V25.8002Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div className="w-full">
                    <h4 className="mb-1 text-xl font-bold text-dark font-spartan">
                      Our Office
                    </h4>
                    <a target="_blank" rel='noreferrer' onClick={handleLocationSuccess} id="WS_Office_contactPage" href="https://maps.app.goo.gl/aijdNdgpSzWqxm4o6" className="underline underline-offset-2 text-base text-body-color font-montserrat">
                    SCO-61,GROUND FLOOR, OMAXE WORLD STREET, Sector 79, Faridabad, Haryana 121004
                    </a>
                  </div>
                </div>

                <div className="mb-8 flex w-full max-w-[370px]">
                  <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-[#6495ed] sm:h-[70px] sm:max-w-[70px]">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_941_17577)">
                        <path
                          d="M24.3 31.1499C22.95 31.1499 21.4 30.7999 19.7 30.1499C16.3 28.7999 12.55 26.1999 9.19997 22.8499C5.84997 19.4999 3.24997 15.7499 1.89997 12.2999C0.39997 8.59994 0.54997 5.54994 2.29997 3.84994C2.34997 3.79994 2.44997 3.74994 2.49997 3.69994L6.69997 1.19994C7.74997 0.599942 9.09997 0.899942 9.79997 1.89994L12.75 6.29994C13.45 7.34994 13.15 8.74994 12.15 9.44994L10.35 10.6999C11.65 12.7999 15.35 17.9499 21.25 21.6499L22.35 20.0499C23.2 18.8499 24.55 18.4999 25.65 19.2499L30.05 22.1999C31.05 22.8999 31.35 24.2499 30.75 25.2999L28.25 29.4999C28.2 29.5999 28.15 29.6499 28.1 29.6999C27.2 30.6499 25.9 31.1499 24.3 31.1499ZM3.79997 5.54994C2.84997 6.59994 2.89997 8.74994 3.99997 11.4999C5.24997 14.6499 7.64997 18.0999 10.8 21.2499C13.9 24.3499 17.4 26.7499 20.5 27.9999C23.2 29.0999 25.35 29.1499 26.45 28.1999L28.85 24.0999C28.85 24.0499 28.85 24.0499 28.85 23.9999L24.45 21.0499C24.45 21.0499 24.35 21.0999 24.25 21.2499L23.15 22.8499C22.45 23.8499 21.1 24.1499 20.1 23.4999C13.8 19.5999 9.89997 14.1499 8.49997 11.9499C7.84997 10.8999 8.09997 9.54994 9.09997 8.84994L10.9 7.59994V7.54994L7.94997 3.14994C7.94997 3.09994 7.89997 3.09994 7.84997 3.14994L3.79997 5.54994Z"
                          fill="currentColor"
                        />
                        <path
                          d="M29.3 14.25C28.7 14.25 28.25 13.8 28.2 13.2C27.8 8.15003 23.65 4.10003 18.55 3.75003C17.95 3.70003 17.45 3.20003 17.5 2.55003C17.55 1.95003 18.05 1.45003 18.7 1.50003C24.9 1.90003 29.95 6.80003 30.45 13C30.5 13.6 30.05 14.15 29.4 14.2C29.4 14.25 29.35 14.25 29.3 14.25Z"
                          fill="currentColor"
                        />
                        <path
                          d="M24.35 14.7002C23.8 14.7002 23.3 14.3002 23.25 13.7002C22.95 11.0002 20.85 8.90018 18.15 8.55018C17.55 8.50018 17.1 7.90018 17.15 7.30018C17.2 6.70018 17.8 6.25018 18.4 6.30018C22.15 6.75018 25.05 9.65018 25.5 13.4002C25.55 14.0002 25.15 14.5502 24.5 14.6502C24.4 14.7002 24.35 14.7002 24.35 14.7002Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_941_17577">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="w-full">
                    <h4 className="mb-1 text-xl font-bold text-dark font-spartan">
                      Phone Number
                    </h4>
                    <a target="_blank" rel='noreferrer' href="tel:+919910109700" className="underline underline-offset-2 text-base text-body-color font-montserrat">
                        +919910109700
                    </a>
                  </div>
                </div>

                <div className="mb-8 flex w-full max-w-[370px]">
                  <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-[#6495ed] sm:h-[70px] sm:max-w-[70px]">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28 4.7998H3.99998C2.29998 4.7998 0.849976 6.1998 0.849976 7.9498V24.1498C0.849976 25.8498 2.24998 27.2998 3.99998 27.2998H28C29.7 27.2998 31.15 25.8998 31.15 24.1498V7.8998C31.15 6.1998 29.7 4.7998 28 4.7998ZM28 7.0498C28.05 7.0498 28.1 7.0498 28.15 7.0498L16 14.8498L3.84998 7.0498C3.89998 7.0498 3.94998 7.0498 3.99998 7.0498H28ZM28 24.9498H3.99998C3.49998 24.9498 3.09998 24.5498 3.09998 24.0498V9.2498L14.8 16.7498C15.15 16.9998 15.55 17.0998 15.95 17.0998C16.35 17.0998 16.75 16.9998 17.1 16.7498L28.8 9.2498V24.0998C28.9 24.5998 28.5 24.9498 28 24.9498Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div className="w-full">
                    <h4 className="mb-1 text-xl font-bold text-dark font-spartan">
                      Email Address
                    </h4>
                    <a target="_blank" rel='noreferrer' href="mailto:sales@acmespaces.com" className="underline underline-offset-2 text-base text-body-color font-montserrat">
                      sales@acmespaces.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2 xl:w-5/12 flex flex-col justify-center">
                <div className="font-spartan text-center text-[24px]">Submit a Query</div>
              <div className="relative rounded-lg bg-white p-8 shadow-lg sm:p-12 font-montserrat">
                <form onSubmit={handleSubmit(onSubmit)} id="contactUs-form"
                > 
                  <div className={`mb-${errors.name ? ["0.5em"] : 6}`}>
                  <input
                    className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary"
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    {...register("name", {
                        required: {
                          value: true,
                          message: "Please enter your name*",
                        },
                        maxLength: {
                          value: 30,
                          message:
                            "Please use 30 characters or less",
                        },
                      })}
                  />
                  {errors.name && (
                      <span className="errorMessage">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                  
                  <div className={`mb-${errors.email ? ["0.5em"] : 6}`}>
                  <input
                    className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary"
                    type="text"
                    name="email"
                    placeholder="Your Email"
                    {...register("email", {
                        required: true,
                        pattern:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]{3,}\.[a-zA-Z0-9-]+/
                      })}
                  />
                  {errors.email && (
                    <span className="errorMessage">
                      Please enter a valid email address*
                    </span>
                  )}
                  </div>
                  
                  <div className={`mb-${errors.phone ? ["0.5em"] : 6}`}>
                  <input
                    className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary"
                    type="number"
                    name="phone"
                    placeholder="Your Phone"
                    {...register("phone", {
                        required: {
                          value: true,
                          message: "Please enter a valid Number*",
                        },
                        maxLength: {
                          value: 10,
                          message:
                            "phone cannot exceed 10 characters",
                        },
                        validate: validatePhoneNumber
                      })}
                  />
                  {errors.phone && (
                      <span className="errorMessage">
                        {errors.phone.message}
                      </span>
                    )}
                  </div>
                  <div className={`mb-${errors.message ? ["0.5em"] : 6}`}>
                  <textarea
                    className="w-full resize-none rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary"
                    row="6"
                    placeholder="Your Message"
                    name="message"
                    defaultValue=""
                    {...register("message", {
                        required: true,
                      })}
                  />
                  {errors.message && (
                    <span className="errorMessage">
                      Please enter a message*
                    </span>
                  )}
                  </div>
                  <div>
                  <button type="submit" id="sbmtBtn" className="w-full rounded border border-primary bg-primary p-3 text-white transition hover:bg-opacity-90">Submit
                  </button>

                  <button type="submit" id="sbmtBtnShow" className="hidden items-center justify-center w-full rounded border border-primary bg-primary p-3 text-white transition hover:bg-opacity-90">Submit
                  <svg class="animate-spin -mr-1 sm:ml-3 ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* OFFICE CARDS */}
      <section>
      <div className="container mx-auto">
        <div className="flex items-center justify-center md:mt-[100px] md:mb-[70px] sm:mt-[55px] sm:mb-[40px] mt-[70px] mb-[10px]">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="458" height="2" viewBox="0 0 458 2" className="max-2xl:w-[300px] max-xl:w-[200px] max-lg:w-[120px] max-md:w-[110px] max-sm:hidden" fill="none">
                    <path d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1 2H458V0L1 0V2Z" fill="#D9D9D9"/>
                    </svg>
                </div>
                    <div className="max-lg:hidden text-black text-5xl font-spartan font-semibold mx-[14px] max-md:text-[40px] max-sm:text-[28px] whitespace-nowrap">Our Offices In Faridabad</div>
                    <div className="lg:hidden text-black font-spartan font-semibold mx-[14px] max-lg:text-[48px] max-md:text-[40px] max-sm:text-[28px]">Our Locations</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="458" height="2" viewBox="0 0 458 2" className="max-2xl:w-[300px] max-xl:w-[260px] max-lg:w-[120px] max-md:w-[110px] max-sm:hidden" fill="none">
                    <path d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1 2H458V0L1 0V2Z" fill="#D9D9D9"/>
                    </svg>
                </div>
            </div>

            <div className="max-lg:hidden mb-[170px] flex justify-center">
                <div className="flex 2xl:w-[429px] 2xl:h-[200px] w-[390px] h-[180px] bg-white rounded-[14px] shadow transition-all hover:bg-[#EDF5F7] card-hover" style={{boxShadow:"rgba(0, 0, 0, 0.25) 2px 4px 27.5px 0px"}}>
                    <img className="my-[9px] mx-[9px] 2xl:w-[182px] 2xl:h-[182px] w-[160px] h-[160px] rounded-[10px]" src="https://ik.imagekit.io/AcmeSpaces/offices/World%20street%20office.png" alt="office-front" />
                    <div className="flex flex-col mb-[35px] ml-[5px] justify-end">
                        <div className="text-[#13293D] xl:text-[22px] text-[16px] font-semibold font-spartan">
                        World Street <br/> office
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="192" height="2" className="max-xl:w-[120px]" viewBox="0 0 192 2" fill="none">
                            <path d="M0 1H192" stroke="#D9D9D9"/>
                        </svg>
                        <div className="flex items-center pt-[4px]">
                            <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/aijdNdgpSzWqxm4o6" className="text-[#6495ED] xl:text-lg text-sm font-semibold font-spartan underline">View on maps</a>
                            <span className="ml-[2px]" >
                                <CustomSVG iconName="diagonalArrow"/>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="flex mx-[20px] 2xl:w-[429px] 2xl:h-[200px] w-[390px] h-[180px] bg-white rounded-[14px] shadow transition-all hover:bg-[#EDF5F7] card-hover" style={{boxShadow:"rgba(0, 0, 0, 0.25) 2px 4px 27.5px 0px"}}>
                    <img className="my-[9px] mx-[9px] 2xl:w-[182px] 2xl:h-[182px] w-[160px] h-[160px] rounded-[10px]" src="https://ik.imagekit.io/AcmeSpaces/offices/Puri%20High%20street%20Office.png" alt="office-front" />
                    <div className="flex flex-col mb-[35px] ml-[5px] justify-end">
                        <div className="text-[#13293D] xl:text-[22px] text-[16px] font-semibold font-spartan">
                        Puri High Street<br/> office
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="192" height="2" className="max-xl:w-[120px]" viewBox="0 0 192 2" fill="none">
                            <path d="M0 1H192" stroke="#D9D9D9"/>
                        </svg>
                        <div className="flex items-center pt-[4px]">
                            <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/i3JboDnpQsZAiBKP8" className="text-[#6495ED] xl:text-lg text-sm font-semibold font-spartan underline">View on maps</a>
                            <span className="ml-[2px]" >
                                <CustomSVG iconName="diagonalArrow"/>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="flex 2xl:w-[429px] 2xl:h-[200px] w-[390px] h-[180px] bg-white rounded-[14px] shadow transition-all hover:bg-[#EDF5F7] card-hover" style={{boxShadow:"rgba(0, 0, 0, 0.25) 2px 4px 27.5px 0px"}}>
                    <img className="my-[9px] mx-[9px] 2xl:w-[182px] 2xl:h-[182px] w-[160px] h-[160px] rounded-[10px]" src="https://ik.imagekit.io/AcmeSpaces/offices/Omaxe%20Hills%20and%20Forest%20spa%20office.png" alt="office-front"/>
                    <div className="flex flex-col mb-[35px] ml-[5px] justify-end">
                        <div className="text-[#13293D] xl:text-[22px] text-[16px] font-semibold font-spartan">
                        Omaxe Hills and <br/> Forest Spa office
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="192" height="2" className="max-xl:w-[120px]" viewBox="0 0 192 2" fill="none">
                            <path d="M0 1H192" stroke="#D9D9D9"/>
                        </svg>
                        <div className="flex items-center pt-[4px]">
                            <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/esYfHvQkGiDwnarP6" className="text-[#6495ED] xl:text-lg text-sm font-semibold font-spartan underline">View on maps</a>
                            <span className="ml-[2px]" >
                                <CustomSVG iconName="diagonalArrow"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>



            <div className="flex justify-center mb-[2.5em]">
                <div className="lg:hidden sm:w-[600px]">
                    <Slider {...settings}
                        className="container mx-auto">
                    {sliderData.map((d) => (                    
                        <div className="my-6 !block !w-max ms-auto me-auto">
                                <div className="!flex shadow sm:!w-[390px] office-cards w-[280px] rounded-[14px] transition-all hover:bg-[#EDF5F7] shadow card-hover bg-white" style={{boxShadow:"rgba(0, 0, 0, 0.25) 2px 4px 27.5px 0px"}}>
                                    <img className="office-cards-img my-[9px] mx-[9px] 2xl:w-[182px] 2xl:h-[182px] sm:w-[160px] sm:h-[160px] w-[130px] h-[130px] rounded-[10px]" src={d.img} alt="office-front"/>
                                    <div className="flex flex-col office-card-margin mb-[35px] ml-[5px] justify-end">
                                        <div className="text-[#13293D] sm:text-xl text-sm font-semibold font-spartan">
                                        {d.span1}<br/>{d.span2}
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="192" height="2" className="max-xl:w-[120px] office-cards-hr" viewBox="0 0 192 2" fill="none">
                                            <path d="M0 1H192" stroke="#D9D9D9"/>
                                        </svg>
                                        <div className="flex items-center pt-[4px]">
                                            <a target="_blank" rel="noreferrer" href={d.mapLink} className="text-[#6495ED] md:text-base sm:text-sm  font-semibold office-card-text font-spartan underline">View on maps</a>
                                        <span className="ml-[2px]" >
                                            <CustomSVG iconName="diagonalArrow"/>
                                        </span>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            ))}
                        </Slider>
                </div>
            </div>
        </div>
      </section>
      <ToastContainer/>
    </>
  );
};

export default Contact;

