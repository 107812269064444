import React from 'react';

const handleLocationSuccess = () => {
    // Push the event to the dataLayer
    window.dataLayer.push({
        event: 'mapsOpenSuccess',
        officeLocation: 'success'
    })}

export default function PlotsizeUpdate() {
    return (
        <>
            <section className="container mx-auto lg:max-w-4xl md:max-w-2xl pb-[40px] m-blogs">
                <div>
                    <nav className="w-full font-montserrat mt-[32px]">
                        <ol className="list-reset flex max-md:text-[14px]">
                            <li>
                                <a href="/" className="text-primary transition duration-150 ease-in-out hover:text-[#13293d] focus:text-[#13293d] active:text-[#60a5fa]">Home</a>
                            </li>
                            <li>
                                <span className="mx-2 text-black">/</span>
                            </li>
                            <li>
                                <a href="/#blog" className="text-primary transition duration-150 ease-in-out hover:text-[#13293d] focus:text-[#13293d] active:text-[#60a5fa]">Blogs</a>
                            </li>
                            <li>
                                <span className="mx-2 text-black">/</span>
                            </li>
                            <li className="text-black">HSVP Plot Measurement Update</li>
                        </ol>
                    </nav>
                </div>

                <section className="max-md:mx-[10px] max-sm:mx-[0px]">
                    <div className="flex flex-col">
                        <div className="md:text-[36px] text-[24px] font-spartan pt-[20px] leading-tight text-[#13293d]">New Update in Measurement for HSVP Plots</div>
                        <hr className="my-[5px]"/>
                    </div>

                    <div className="overflow-hidden object-cover md:h-[400px] h-[200px] object-center my-[10px] lg:rounded-lg">
                        <img src="https://ik.imagekit.io/AcmeSpaces/BlogMeasurementUnits.png" alt="hsvp img" className="h-full w-full overflow-hidden object-cover"/>
                    </div>
                    <br/>

                    <div>
                        <section className="flex-col flex font-montserrat text-left gap-[2px]">
                            <div className="md:text-[18px] text-[16px]">
                                The Haryana Shahari Vikas Pradhikaran (HSVP) has updated the unit of measurement for plots from square yards to square meters. Here’s a quick overview of what this means for plot owners.
                            </div>
                            <br/>
                            <div className="md:text-[18px] text-[16px]">
                                <strong className="text-[#13293d]">What Does This Change Mean?</strong>
                                <ul className="list-disc pl-5">
                                    <li><strong>Standardization:</strong> Standard plot dimensions have changed slightly in HSVP sectors</li>
                                    <li><strong>Conversion:</strong> 1 square meter = 1.196 square yards.</li>
                                    {/* <li><strong>Revised Plot Sizes:</strong> The plot sizes have been updated to reflect the new measurement unit.</li> */}
                                </ul>
                            </div>
                            <br/>

                            <div className="md:text-[18px] text-[16px]">
                                <strong className="text-[#13293d]">New Plot Sizes</strong>
                                <table className="table-auto w-full mt-4 text-[16px]">
                                    <thead>
                                        <tr>
                                            <th className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 bg-[lightgray] max-sm:text-[12px] text-[#13293d]">Plot Size</th>
                                            <th className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 bg-[lightgray] max-sm:text-[12px] text-[#13293d]">OLD</th>
                                            <th className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 bg-[lightgray] max-sm:text-[12px] text-[#13293d]">NEW</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">4M</td>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">100 SQYD</td>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">107 SQYD</td>
                                        </tr>
                                        <tr>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">6M</td>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">150 SQYD</td>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">160 SQYD</td>
                                        </tr>
                                        <tr>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">8M</td>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">200 SQYD</td>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">204 SQYD</td>
                                        </tr>
                                        <tr>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">10M</td>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">250 SQYD</td>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">264 SQYD</td>
                                        </tr>
                                        <tr>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">14M</td>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">350 SQYD</td>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">388 SQYD</td>
                                        </tr>
                                        <tr>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">1K</td>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">500 SQYD</td>
                                            <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">538 SQYD</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br/>

                            <div className="md:text-[18px] text-[16px]">
                                <strong className="text-[#13293d]">Why This Matters</strong>
                                <ul className="list-disc pl-5">
                                    <li>Architectural Drawings would now have slightly different dimensions</li>
                                </ul>
                            </div>

                            <br/>
                            <div>
                            Stay informed and plan accordingly to make the most of your HSVP plot. For more updates, keep following our blog!
                            </div>
                            <br/>


                            <div className="md:text-[18px] text-[16px]">
                            <a target="_blank" rel='noreferrer' href="https://wa.link/aj5vnq" className="underline text-[#13293d] font-bold">Get in touch with us today</a> in case you need:
                            <ul className="list-image-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjMzhiZGY4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy42ODUuMTUzYS43NTIuNzUyIDAgMCAxIC4xNDMgMS4wNTJsLTggMTAuNWEuNzUuNzUgMCAwIDEtMS4xMjcuMDc1bC00LjUtNC41YS43NS43NSAwIDAgMSAxLjA2LTEuMDZsMy44OTQgMy44OTMgNy40OC05LjgxN2EuNzUuNzUgMCAwIDEgMS4wNS0uMTQzWiIgLz48L3N2Zz4=')]">
                                <li>Buy or Sell Plots.</li>
                                <li>Advice on Real Estate investment.</li>
                                <li>To Apply for a bank loan for your property.</li>
                            </ul>
                            </div>
                            
                            <br/>
                            <div className="md:text-[22px] text-[20px] font-semibold">Contact Info:</div>
                            <div className="md:text-[18px] text-[16px] flex flex-col">
                            <span className="text-left">Phone: <a target="_blank" rel='noreferrer' href="tel:+919910109700" className="underline">9910109700</a></span>
                            <span className="text-left">Location: <a target="_blank" rel='noreferrer' onClick={handleLocationSuccess} id='WS_Office_Plots_blog' href="https://maps.app.goo.gl/aijdNdgpSzWqxm4o6" className="underline">SCO-61, OMAXE WORLD STREET, Sector 79, Faridabad, Haryana 121004</a></span>
                            </div>
                        </section>
                    </div>
                </section>
            </section>
        </>
    );
}
