    import React from "react";
    import {
    TEModal,
    } from "tw-elements-react";
import CustomSVG from "./svg/CustomSvg";
    

    function AmentiesPopup({showModal, setShowModal, brandProps, floorHighlightsProps })  {
        // console.log("brandused", brandProps);
        // console.log("floorHighlightsProps", floorHighlightsProps);
        
        return (
        <div className="container font-HK mx-auto 2xl:max-w-7xl xl:max-w-6xl max-w-[1000px]">
        {/* Modal */}
        <TEModal show={showModal} setShow={setShowModal} scrollable className="open-bottomup-modal-animation flex flex-col items-center md:justify-center justify-end max-md:pb-[90px]">
        <div className="lg:container mx-auto text-black bg-white rounded-xl shadow flex-col justify-start inline-flex justify-center justify-evenly max-lg:h-fit max-lg:w-full max-lg:absolute max-lg:top-0">


            <div className="text-right !sticky !top-0 bg-[#D9D9D9]">
                <button className="my-[20px]" onClick={() => setShowModal(false)}>
                <svg className="mr-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18M6 6L18 18" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </button>
            </div>

            <div className="mx-auto flex flex-col">
                <div className="flex container items-center mx-auto">
                    <button onClick={() => setShowModal(false)} className="max-lg:hidden relative left-[-20px]"><CustomSVG iconName="gobackIcon"/></button>
                    <div className="text-[#13293D] xl:text-[32px] text-[28px] font-bold font-HK">Amenties</div>
                </div>
                
                <hr className="my-[5px]"/>
                
                <div className="mt-[10px] max-lg:mb-[10px]">
                    <span className="text-[#13293D] xl:text-[28px] text-[26px] font-bold font-HK">Floor Highlights</span>
                </div> 

                    <div className="flex">
                        <div className="flex container justify-between">
                            <div className="lg:columns-3 flex-auto">
                            {floorHighlightsProps.map((d) => (
                                <>
                                    <div className="flex items-center 2xl:gap-[25px] lg:gap-[20px] gap-[5px] lg:py-[20px] pb-[15px]">
                                        <span className="justify-center highlights-modalsvg">
                                        <CustomSVG  iconName={d.iconName}/> 
                                        </span>
                                        <div className="text-[#13293D] 2xl:text-[22px] xl:text-[18px] text-[16px] font-bold font-HK text-center leading-normal" style={{whiteSpace: 'pre-line'}}>{d.description}</div>
                                    </div>
                                </>
                            ))}
                            </div>
                        </div>
                    </div>

                    <hr className="my-[5px]"/>
                        <div className="mt-[20px] max-lg:mb-[10px]">
                            <span className="text-[#13293D] xl:text-[28px] text-[26px] font-bold font-HK">Brand Used</span>
                        </div> 
                            <div className="flex">
                                <div className="flex container justify-between">
                                    <div className="lg:columns-3 flex-auto">
                                    {brandProps.map((d) => (
                                        <>
                                            <div className="flex items-center 2xl:gap-[25px] lg:gap-[20px] gap-[5px] lg:py-[20px] pb-[15px]">
                                                <span className="justify-center brands-modalsvg">
                                                <CustomSVG iconName={d.iconName}/> 
                                                </span>
                                                <div className="text-[#13293D] 2xl:text-[22px] xl:text-[18px] text-[16px] font-bold font-HK text-center leading-normal" style={{whiteSpace: 'pre-line'}}>{d.description}</div>
                                            </div>
                                        </>
                                    ))}
                                    </div>
                                </div>
                            </div>
                    <hr className="mb-[20px]"/>

            </div>
        </div>
        </TEModal>
        </div>
    );
    }

    export default AmentiesPopup;
