import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Home from './Pages/Home';
import About from './Pages/About';
import BuilderFloor from './Pages/Listings/Faridabad/Sector-28/Luxurious_4_BHK_builder_floor';
import PageNoteFound from './Pages/404page';
import ComingSoon from './Pages/ComingSoon';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import HsvpBlog from './Pages/Blogs/HsvpAuction';
import Contact from './Pages/Contact';
import AssuredReturnsBlog from './Pages/Blogs/AssuredReturns';
import SellYourPoroperty from './Pages/SellYourProperty';
import PlotsizeUpdate from './Pages/Blogs/PlotMeasurementHSVP';
import Careers from './Pages/Careers';

function App() {
  const { pathname, hash} = useLocation();

  useEffect(() => {
        // Function to scroll to the element with a specific id and a vertical offset
        const scrollToId = (id) => {
          const element = document.getElementById(id);
          if (element) {
            const navbarHeight = 120; // Height of your fixed navbar
            const offset = element.offsetTop - navbarHeight - window.innerHeight * 0.1; // Adjusted offset
            window.scrollTo({ top: offset, behavior: 'smooth' });
          }
        };
        
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        scrollToId(id);
      }, 0);
    }
  }, [pathname, hash]); // do this on route change

  return (
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/listings/faridabad/luxurious-4-BHK-builder-floor' element={<BuilderFloor/>} />
      <Route path='/coming-soon' element={<ComingSoon/>}/>
      <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
      <Route path='*' element={<PageNoteFound/>}/>
      <Route path='/404' element={<PageNoteFound/>}/>
      <Route path='/blog/hsvp-auction' element={<HsvpBlog/>}/>
      <Route path='/blog/assured-returns' element={<AssuredReturnsBlog/>}/>
      <Route path='/blog/hsvp-plot-measurement-update' element={<PlotsizeUpdate/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/sell-your-property' element={<SellYourPoroperty/>}/>
      <Route path='/careers' element={<Careers/>}/>
    </Routes>

    );
}

export default App;
