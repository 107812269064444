    // import InstagramEmbed from "react-instagram-embed";
    import CustomSVG from "../../../../assets/svg/CustomSvg";
    // import InstagramEmbed from 'react-instagram-embed';
    import React, { useState, useEffect} from 'react';
    // import InstaOEmbed from "../../../../assets/svg/InstaOEmbed";
    import AmentiesPopup from "../../../../assets/ModalAllAmenties";
    import ImgGalleryPopup from "../../../../assets/ModalGallery";
    import { TECarousel, TECarouselItem } from "tw-elements-react";
    import { InstagramEmbed } from 'react-social-media-embed';
    import { RWebShare } from "react-web-share";

    export default function BuilderFloor() {
        const [showModal, setShowModal] = useState(false);
        const [showImgModal, setShowImgModal] = useState(false);
        const [floorData, setFloorData] = useState(null);

        useEffect(() => {
            // Define an async function to fetch data from the API
            const fetchData = async () => {
                try {
                    const response = await fetch('https://api.acmespaces.com/api/v1/floorlistings?id=3bdecba8');
                    if (!response.ok) {
                        throw new Error('Failed to fetch data');
                    }
                    const result = await response.json();
                    setFloorData(result);
                    console.log("result : ", result);
                } catch (error) {
                    console.error('Error fetching data:', error.message);
                }
            };
            fetchData();
        }, []);
        return (    
            <>
            {floorData ? (
            <div className="container font-HK mx-auto 2xl:max-w-7xl xl:max-w-6xl max-w-[1000px]">

            <div>
            <TECarousel
                className="z-[1] lg:hidden"
                showControls
                // showIndicators
                crossfade
                ride="carousel"
                prevBtnIcon={
                    <>
                        <span className="inline-block text-black h-8 w-8 [&>svg]:h-8">
                        <CustomSVG iconName="heroPrevBtnIcon" />
                        </span>
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Previous
                        </span>
                    </>
            }
                nextBtnIcon={
                <>
                    <span className="inline-block text-black h-8 w-8 [&>svg]:h-8">
                    <CustomSVG iconName="heroNextBtnIcon" />
                    </span>
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Next
                    </span>
                </>
            }
                theme={{
                indicator:
                    "mx-1 max-lg:h-[8px] max-lg:w-[8px] w-[10px] h-[10px] bg-[#737272] opacity-50 rounded-full cursor-pointer opacity-50 hover:opacity-100 transition-opacity duration-600 ease-in-out relative top-[40px]"  
                    /*mx-[3px]  box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-black bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none*/,
            }}
        >
            <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
            {floorData.ImageLinks.map((d) => (
                <TECarouselItem
                    itemID={1}
                    className="relative float-left -mr-[100%] hidden w-full !transform-none transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
                >
                <img
                    // src="https://ik.imagekit.io/AcmeSpaces/heroslider1.png"
                    src={d.imgUrl}
                    className="block 2xl:w-[100vw] sm:aspect-[144/41] aspect-[5/4] max-lg:w-full object-cover object-left max-lg:h-[290px] lg:w-full"
                    alt="..."
                />
                </TECarouselItem>
            ))}
            <button onClick={() => setShowImgModal(true)} className="lg:hidden z-[100] flex showImgs-btn 2xl:right-[65px] xl:right-[40px] bottom-[8px] right-[8px] px-[10px] items-center gap-[10px] xl:h-12 h-[2.5rem] bg-white rounded-xl absolute"><CustomSVG iconName="imagesIcon"/><span className=" text-black xl:text-xl text-[16px] font-semibold font-HK">show all images</span></button>
            </div>
            </TECarousel>
            </div>


            <div className="text-black xl:text-4xl lg:text-3xl sm:text-4xl text-3xl font-bold lg:mt-[32px] mt-[20px] mb-[20px] max-lg:container max-lg:mx-auto">
                {floorData.propertyname}
            </div>
            
            <div className="max-lg:hidden overflow-hidden rounded-[12px]">
                <div className="h-[300px] columns-2 gap-[0px] object-cover overflow-hidden">
                    
                {floorData.ImageLinks.slice(0, 1).map((item, index) => (    
                    <div onClick={() => setShowImgModal(true)} className="cursor-pointer">
                        <img
                            className="img-hover h-[300px] w-[100%] object-cover overflow-hidden"
                            key={item.index}
                            src={item.imgUrl}
                            alt={`${item.index}`}
                        />
                    </div>
                    ))} 

                    <div className="relative columns-2 gap-[0px] property-photos">
                        {floorData.ImageLinks.slice(1, 5).map((item, index) => (
                            <div onClick={() => setShowImgModal(true)} className="cursor-pointer">
                                <img
                                    className={`img-hover pl-[10px] h-[150px] w-[100%] object-cover overflow-hidden ${
                                    index === 0 || index === 2 ? 'pb-[10px]' : ''}`}
                                    style={{}}
                                    key={item.index}
                                    src={item.imgUrl}
                                    alt={`${item.index}`}
                                />
                            </div>
                        ))}
                        <button onClick={() => setShowImgModal(true)} className="flex showImgs-btn bottom-[45px] 2xl:right-[65px] xl:right-[40px] right-[35px] px-[10px] items-center gap-[10px] xl:h-12 h-[2.5rem] bg-white rounded-xl absolute"><CustomSVG iconName="imagesIcon"/><span className=" text-black xl:text-xl text-[16px] font-semibold font-HK">show all images</span></button>
                    </div>

                </div>
            </div>
                
                
                
                

            {/* <p className="hidden"> */}
                    

                <div className="lg:flex lg:justify-between  lg:mt-[70px] mt-[40px] max-lg:container max-lg:mx-auto">
                    <div className="flex flex-col columns-2">
                        <div className="xl:text-[40px] sm:text-[32px] text-[28px] font-semibold">Property Information</div>
                        <div className="border-solid border-[3px] border-[#6495ED] mr-[16px] sm:w-[533px] w-[300px] mt-[10px]"></div>

                        {/* <div className="text-[#13293D] xl:text-[33px] text-[28px] font-normal mt-[24px] xl:leading-[46px] leading-[35px]">
                        Welcome to a stylish and modern living environment in of Faridabad's Sector-28, only a short distance from Badhkal Chowk. With four bedrooms, four bathrooms, and a separate drawing room, this large builder floor measuring 367 square yards offers plenty of area for your family to grow.
                        </div> */}

                            <div className="lg:columns-2 max-lg:flex max-lg:flex-col max-lg:place-items-center xl:mt-[100px] sm:mt-[60px] mt-[40px]">
                                {floorData.PropertyHighlights.map((d) => (
                                    <>
                                    <div class="mb-4 grid content-center 2xl:w-[396px] xl:w-[350px] sm:w-[320px] w-[280px] 2xl:h-[148px] xl:h-[130px] h-[110px] bg-white border-2 border-zinc-300">
                                        <div className="text-[#6495ED] 2xl:text-3xl xl:text-[28px] text-[24px] font-semibold font-HK text-center">{d.highlightValue}</div>
                                        <span className="2xl:mx-[20px] mx-[15px] 2xl:my-[14px] my-[08px]" style={{strokeWidth:"2px", background:"#6495ED", height:"2px", display:"block"}}/>
                                        <div className="text-[#757575] 2xl:text-[26px] xl:text-[22px] text-[20px] font-medium font-HK text-center">{d.highlightName}</div>
                                    </div>
                                    </>
                                ))}
                            </div>
                            <div className="flex justify-start xl:items-end xl:h-full max-xl:pt-[5em] max-lg:py-[2em] max-lg:justify-center max-sm:flex-col max-sm:items-center max-lg:container max-lg:mx-auto gap-4">
                                <RWebShare
                                    data={{
                                        text: "ACME SPACES: Luxurious 4 BHK Builder Floor in Sector-28, Faridabad",
                                        url: "https://dev.acmespaces.com/listings/faridabad/luxurious-4-BHK-builder-floor",
                                        title: "ACME SPACES: Builder Floor, Faridabad",
                                    }}
                                    onClick={() => console.log("shared successfully!")}>
                                        <button className="showImgs-btn cursor-pointer items-center flex max-xl:gap-[8px] w-[180px] justify-center" style={{background:"#6495ED", fontSize:"22px", height: "48px"}}> 
                                            <span className="font-HK text-white">Share</span>
                                            <span><CustomSVG iconName="shareIcon"/></span>
                                        </button>
                                </RWebShare>
                                <div className="showImgs-btn cursor-pointer items-center flex max-xl:gap-[8px] w-[180px] justify-center" style={{background:"#5FD669", fontSize:"22px", height: "48px"}}> 
                                    <span className="font-HK text-white">Contact Us</span>
                                    <span><CustomSVG iconName="whatsappIcon"/></span>
                                </div>
                            </div>
                        {/* <div className="max-w-full" ></div> */}
                    </div>
                        <div className="max-lg:container max-lg:mx-auto max-lg:flex max-lg:justify-center max-sm:mt-[40px]">
                            <div className="w-[325px]">
                            <InstagramEmbed url="https://www.instagram.com/reel/C4iNevwv9Cd/" width={328} />
                            </div>
                        </div>
                </div>

                <div className="hidden flex justify-start xl:items-end xl:h-full max-xl:pt-[5em] max-lg:pt-[2em] max-lg:container max-lg:mx-auto gap-4">
                    <div className="bg-[#5FD669] items-center flex xl:px-[12px] py-[12px] px-[20px] max-xl:gap-[12px] w-[160px]"> 
                        <span className="font-HK text-white xl:text-[32px] sm:text-[26px] text-[22px] font-medium">Share</span>
                        <span><CustomSVG iconName={`whatsappIcon${window.innerWidth < 640 ? 'Mob' : ''}`} /></span>
                    </div>
                    <div className="bg-[#5FD669] items-center flex xl:px-[12px] py-[12px] px-[20px] max-xl:gap-[12px] w-[160px]"> 
                        <span className="font-HK text-white xl:text-[32px] sm:text-[26px] text-[22px] font-medium">Contact Us</span>
                        <span><CustomSVG iconName={`whatsappIcon${window.innerWidth < 640 ? 'Mob' : ''}`} /></span>
                    </div>
                </div>

                {/* <div className="flex max-lg:flex-col max-lg:gap-[24px] max-lg:items-center justify-between xl:mt-[100px] mt-[60px]">
                    {PropertyHighlights.map((d) => (
                        <>
                        <div class="grid content-center 2xl:w-[396px] xl:w-[350px] w-[320px] 2xl:h-[148px] xl:h-[130px] h-[110px] bg-white border-2 border-zinc-300">
                            <div className="text-[#6495ED] 2xl:text-3xl xl:text-[28px] text-[24px] font-semibold font-HK text-center">{d.highlightValue}</div>
                            <span className="2xl:mx-[20px] mx-[15px] 2xl:my-[14px] my-[08px]" style={{strokeWidth:"2px", background:"#6495ED", height:"2px", display:"block"}}/>
                            <div className="text-[#757575] 2xl:text-[26px] xl:text-[22px] text-[20px] font-medium font-HK text-center">{d.highlightName}</div>
                        </div>
                        </>
                    ))}
                </div> */}

                <div className="container max-lg:mx-auto lg:mt-[100px] mt-[70px]">
                        <div className="text-[#13293D] xl:text-[42px] text-[32px] font-bold font-HK max-sm:text-center">Amenities</div>
                        <hr className="xl:my-[20px] my-[10px]"/>
                        <div className="text-[#13293D] xl:text-[32px] sm:text-[28px] text-[22px] font-bold font-HK mt-[10px]">Connectivity</div>
                        <div className="flex">
                            <div className="lg:flex max-lg:grid max-lg:grid-cols-2 lg:container mx-auto justify-between py-[20px] max-lg:gap-[40px]">
                                {floorData.connectivity.map((d) => (
                                    <>
                                    <div className="sm:w-[165px] w-[130px]">
                                            <span className="flex justify-center connectivity-svg">
                                            <CustomSVG  iconName={d.iconName}/>
                                            </span>
                                            <div className="text-[#13293D] xl:text-[22px] sm:text-[18px] text-[16px] font-semibold font-HK text-center leading-normal" style={{whiteSpace: 'pre-line'}}>{d.description}</div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                        <hr className="my-[20px]"/>
                        <div className="text-[#13293D] xl:text-[32px] sm:text-[28px] text-[22px] font-bold font-HK mt-[10px] max-sm:mb-[20px]">Floor Highlights</div>
                        <div className="flex">
                            <div className="flex container justify-between">
                                <div className="lg:columns-3 mx-auto text-justify">
                                {floorData.floorHighlights.map((d) => (
                                    <>
                                        <div className="flex items-center 2xl:gap-[25px] sm:gap-[20px] gap-[5px] sm:py-[20px] pb-[20px]">
                                            <span className="justify-center highlights-svg">
                                            <CustomSVG  iconName={d.iconName}/>
                                            </span>
                                            <div className="text-[#13293D] 2xl:text-[22px] xl:text-[18px] text-[16px] font-bold font-HK text-center leading-normal" style={{whiteSpace: 'pre-line'}}>{d.description}</div>
                                        </div>
                                    </>
                                ))}
                                </div>
                            </div>
                        </div>
                        <hr className="my-[20px]"/>
                        <button onClick={() => setShowModal(true)} className="text-[#6495ED] xl:text-[26px] sm:text-[22px] text-[18px] font-bold font-HK">View all Amenities -&gt;</button>

                

                    <div class="flex justify-center lg:my-[100px] my-[70px]">
                            <div class="rounded-[10px] border-2 border-slate-800">
                            <div class="text-black sm:text-2xl text-[16px] font-bold font-HK px-[20px] py-[6px]">View All Similar Listing</div>
                            </div>
                        </div>
                </div>

                {/* </p> */}
                

                <AmentiesPopup 
                    showModal ={showModal}
                    setShowModal={setShowModal} 
                    brandProps = {floorData.brandUsed}
                    floorHighlightsProps = {floorData.floorHighlights}
                />

                <ImgGalleryPopup 
                showImgModal ={showImgModal}
                setShowImgModal={setShowImgModal} 
                imglinkProps = {floorData.ImageLinks}/>
                </div>
                ) : (
                    <div className="flex justify-center h-[60vh] items-center">
                        <div
                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status">
                            <span
                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                            >Loading...</span>
                        </div>
                    </div>
                )}
            </>
        )
    }