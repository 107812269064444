import { useForm } from "react-hook-form";
import axios from 'axios'; 
import React, { useState} from "react";
import NewsletterPopup from "../assets/ModalNewsletterPopup";

var rootUrl = process.env.REACT_APP_API_URL

export default function ComingSoon() {
    
        const {
            register,
            handleSubmit,
            reset,
            formState: { errors },
        } = useForm();
    
    const [showModal, setShowModal] = useState(false);

    const onSubmit = async (data) => {
        console.log("Submitting form with data:", data);
        console.log("rootUrl:", rootUrl);
        
        try {
            const submitButton = document.getElementById('subscribeBtn');
            submitButton.classList.add('hidden');
        
            const submitButtonShow = document.getElementById('subscribeBtnShow');
            submitButtonShow.classList.add('!flex');
        
            const response = await axios.post(`${rootUrl}/api/v1/addNewsletterEmail`, {
            email: data.email,
        });
            console.log("Response from server:", response);
            if (response.status === 201) {
                reset();
                setShowModal(true);
                const submitButtonShow = document.getElementById('subscribeBtnShow');
                submitButtonShow.classList.add('hidden');
                submitButtonShow.classList.remove('!flex');
        
        
                const submitButton = document.getElementById('subscribeBtn');
                submitButton.classList.add('block');
                submitButton.classList.remove('hidden');
        
        
            console.log("Form sent successfully!");
            } else {
            console.error('Error submitting form:', response.data);
            }
        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
        };
        
     
    return(
        <>
        <div className="comingSoonBG">
            <div     className="flex flex-col container mx-auto h-[80vh] justify-center">
                <div class="text-left text-black md:text-[1.5rem] sm:text-[1rem] text-[0.75rem] mt-[1rem] font-bold font-HK text-bold">#UnderConstruction</div>
                <div class="text-left text-[#13293d] sm:text-[120px] text-[70px] font-extrabold font-HK leading-[1em]">COMING SOON</div>
                <div class="text-left text-black md:text-[1.5rem] sm:text-[1rem] text-[0.75rem] mt-[1rem] font-normal font-HK">This page is #UnderConstruction, Subscribe to our newsLetter to stay updated.</div>



                    <form className='flex items-center sm:pt-8 pt-4'
                        onSubmit={handleSubmit(onSubmit)}
                        noValidate>
                        <div className="lg:w-[872px] sm:w-[600px] w-[320px] sm:h-[58px] h-[44px] bg-white">
                            <input className='bg-white lg:w-[872px] sm:w-[600px] w-[320px] sm:h-[58px] h-[44px] text-[#000] font-HK text-xl font-normal
                            !outline-none pl-[2rem] placeholder:text-neutral-400 sm:placeholder:text-xl placeholder:text-[14px] placeholder:font-normal placeholder:font-HK' placeholder="Enter Your Email" 
                            style={{border:"solid black 2px"}}
                            type="email"
                                name="email"
                                {...register("email", {
                                required: true,
                                pattern:
                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                })}
                        />
                            {errors.email && (
                                <span className="flex font-HK text-[red] ml-6">
                                Please enter a valid email address*
                                </span>
                            )}
                        </div>
                        <div className='footer-subscribe-btn absolute lg:ml-[44.4em] sm:ml-[27.2em] ml-[13.5em] md:border-black md:border-2 md:border-solid'>
                            <button id="subscribeBtn" className='sm:w-[153.99px] w-[100px] sm:h-[43.61px] h-[36px] bg-blue-400  max-sm:text-[14px]'>Subscribe</button>

                            <button id="subscribeBtnShow" className='hidden items-center justify-center sm:w-[153.99px] w-[100px] sm:h-[43.61px] h-[36px] bg-blue-400  max-sm:text-[14px]'>
                            Subscribe
                            <svg class="animate-spin -mr-1 sm:ml-3 ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            </button>
                        </div>

                    </form>

                {/* <div className="flex max-md:flex-col max-md:items-center justify-center sm:gap-[4rem] gap-[2.5rem] mt-[2rem]">
                    <a href="/" className="w-[150px] text-center rounded-[9.38px] bg-[#13293d] font-hk text-white p-[8px] sm:text-base text-xs font-medium">Return Home</a>
                    <a href="mailto:pranjamehta37@acmesapces.com" className="w-[150px] text-center border border-[#13293d] rounded-[9.59px] p-[8px] sm:text-base text-xs font-medium font-hk">Report Problem</a>

                </div> */}
            </div>
        <NewsletterPopup 
            showModal ={showModal}
            setShowModal={setShowModal} 
        />
        </div>
        </>

)}