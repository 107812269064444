import React, {useEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CustomSVG from "../../assets/svg/CustomSvg";
import AOS from 'aos';
import 'aos/dist/aos.css';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        // style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      ><CustomSVG iconName="slickNextArrow"/></div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        // style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      ><CustomSVG iconName="slickPrevArrow"/></div>
    );
  }

const sliderData = [
    {
        img : "https://ik.imagekit.io/AcmeSpaces/offices/World%20street%20office.png",
        span1: "World Street",
        span2: "office",
        mapLink: "https://maps.app.goo.gl/aijdNdgpSzWqxm4o6",
        id:"WS_Office_Card"
    },
    {
        img : "https://ik.imagekit.io/AcmeSpaces/offices/Puri%20High%20street%20Office.png",
        span1: "Puri High Street",
        span2: "office",
        mapLink: "https://maps.app.goo.gl/i3JboDnpQsZAiBKP8",
        id:"PURI_Office_Card"
    },
    {
        img : "https://ik.imagekit.io/AcmeSpaces/offices/Omaxe%20Hills%20and%20Forest%20spa%20office.png",
        span1: "Omaxe Hills and",
        span2: "Forest Spa office",
        mapLink: "https://maps.app.goo.gl/esYfHvQkGiDwnarP6",
        id:"HILLS_Office_Card"
    }
]

const getAnchorPlacement = () => {
    if (window.innerWidth < 768) {
      return "top-bottom";
    } else {
      return "center-bottom";
    }
  }

const handleLocationSuccess = () => {
// Push the event to the dataLayer
window.dataLayer.push({
    event: 'mapsOpenSuccess',
    officeLocation: 'success'
})}

export default function Offices() {
    useEffect(() => {
        AOS.init();
        }, [])
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };

    return(
        <>
        <div className="container mx-auto">
        <div data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement={getAnchorPlacement()} className="flex items-center justify-center md:mt-[100px] md:mb-[70px] sm:mt-[55px] sm:mb-[40px] mt-[70px] mb-[10px]">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="458" height="2" viewBox="0 0 458 2" className="max-2xl:w-[300px] max-xl:w-[200px] max-lg:w-[120px] max-md:w-[110px] max-sm:hidden" fill="none">
                    <path d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1 2H458V0L1 0V2Z" fill="#D9D9D9"/>
                    </svg>
                </div>
                    <div className="max-lg:hidden text-black text-5xl font-spartan font-semibold mx-[14px] max-md:text-[40px] max-sm:text-[28px] whitespace-nowrap">Our Offices In Faridabad</div>
                    <div className="lg:hidden text-black font-spartan font-semibold mx-[14px] max-lg:text-[48px] max-md:text-[40px] max-sm:text-[28px]">Our Locations</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="458" height="2" viewBox="0 0 458 2" className="max-2xl:w-[300px] max-xl:w-[260px] max-lg:w-[120px] max-md:w-[110px] max-sm:hidden" fill="none">
                    <path d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1 2H458V0L1 0V2Z" fill="#D9D9D9"/>
                    </svg>
                </div>
            </div>

            <div className="max-lg:hidden mb-[170px] flex justify-center">
                <div data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement={getAnchorPlacement()} className="flex 2xl:w-[429px] 2xl:h-[200px] w-[390px] h-[180px] bg-white rounded-[14px] shadow transition-all hover:bg-[#EDF5F7] card-hover" style={{boxShadow:"rgba(0, 0, 0, 0.25) 2px 4px 27.5px 0px"}}>
                    <img className="my-[9px] mx-[9px] 2xl:w-[182px] 2xl:h-[182px] w-[160px] h-[160px] rounded-[10px]" src="https://ik.imagekit.io/AcmeSpaces/offices/World%20street%20office.png" alt="office-front" />
                    <div className="flex flex-col mb-[35px] ml-[5px] justify-end">
                        <div className="text-[#13293D] xl:text-[22px] text-[16px] font-semibold font-spartan">
                        World Street <br/> office
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="192" height="2" className="max-xl:w-[120px]" viewBox="0 0 192 2" fill="none">
                            <path d="M0 1H192" stroke="#D9D9D9"/>
                        </svg>
                        <div className="flex items-center pt-[4px]">
                            <a onClick={handleLocationSuccess} id="WS_Office_Card" target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/aijdNdgpSzWqxm4o6" className="text-[#6495ED] xl:text-lg text-sm font-semibold font-spartan underline">View on maps</a>
                            <span className="ml-[2px]" >
                                <CustomSVG iconName="diagonalArrow"/>
                            </span>
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement={getAnchorPlacement()} className="flex mx-[20px] 2xl:w-[429px] 2xl:h-[200px] w-[390px] h-[180px] bg-white rounded-[14px] shadow transition-all hover:bg-[#EDF5F7] card-hover" style={{boxShadow:"rgba(0, 0, 0, 0.25) 2px 4px 27.5px 0px"}}>
                    <img className="my-[9px] mx-[9px] 2xl:w-[182px] 2xl:h-[182px] w-[160px] h-[160px] rounded-[10px]" src="https://ik.imagekit.io/AcmeSpaces/offices/Puri%20High%20street%20Office.png" alt="office-front" />
                    <div className="flex flex-col mb-[35px] ml-[5px] justify-end">
                        <div className="text-[#13293D] xl:text-[22px] text-[16px] font-semibold font-spartan">
                        Puri High Street<br/> office
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="192" height="2" className="max-xl:w-[120px]" viewBox="0 0 192 2" fill="none">
                            <path d="M0 1H192" stroke="#D9D9D9"/>
                        </svg>
                        <div className="flex items-center pt-[4px]">
                            <a onClick={handleLocationSuccess} id="PURI_Office_Card" target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/i3JboDnpQsZAiBKP8" className="text-[#6495ED] xl:text-lg text-sm font-semibold font-spartan underline">View on maps</a>
                            <span className="ml-[2px]" >
                                <CustomSVG iconName="diagonalArrow"/>
                            </span>
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement={getAnchorPlacement()} className="flex 2xl:w-[429px] 2xl:h-[200px] w-[390px] h-[180px] bg-white rounded-[14px] shadow transition-all hover:bg-[#EDF5F7] card-hover" style={{boxShadow:"rgba(0, 0, 0, 0.25) 2px 4px 27.5px 0px"}}>
                    <img className="my-[9px] mx-[9px] 2xl:w-[182px] 2xl:h-[182px] w-[160px] h-[160px] rounded-[10px]" src="https://ik.imagekit.io/AcmeSpaces/offices/Omaxe%20Hills%20and%20Forest%20spa%20office.png" alt="office-front"/>
                    <div className="flex flex-col mb-[35px] ml-[5px] justify-end">
                        <div className="text-[#13293D] xl:text-[22px] text-[16px] font-semibold font-spartan">
                        Omaxe Hills and <br/> Forest Spa office
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="192" height="2" className="max-xl:w-[120px]" viewBox="0 0 192 2" fill="none">
                            <path d="M0 1H192" stroke="#D9D9D9"/>
                        </svg>
                        <div className="flex items-center pt-[4px]">
                            <a onClick={handleLocationSuccess} id="HILLS_Office_Card" target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/esYfHvQkGiDwnarP6" className="text-[#6495ED] xl:text-lg text-sm font-semibold font-spartan underline">View on maps</a>
                            <span className="ml-[2px]" >
                                <CustomSVG iconName="diagonalArrow"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>



            <div className="flex justify-center mb-[2.5em]">
                <div data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement={getAnchorPlacement()} className="lg:hidden sm:w-[600px]">
                    <Slider {...settings}
                        className="container mx-auto">
                    {sliderData.map((d) => (                    
                        <div className="my-6 !block !w-max ms-auto me-auto">
                                <div className="!flex shadow sm:!w-[390px] office-cards w-[280px] rounded-[14px] transition-all hover:bg-[#EDF5F7] shadow card-hover bg-white" style={{boxShadow:"rgba(0, 0, 0, 0.25) 2px 4px 27.5px 0px"}}>
                                    <img className="office-cards-img my-[9px] mx-[9px] 2xl:w-[182px] 2xl:h-[182px] sm:w-[160px] sm:h-[160px] w-[130px] h-[130px] rounded-[10px]" src={d.img} alt="office-front"/>
                                    <div className="flex flex-col office-card-margin mb-[35px] ml-[5px] justify-end">
                                        <div className="text-[#13293D] sm:text-xl text-sm font-semibold font-spartan">
                                        {d.span1}<br/>{d.span2}
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="192" height="2" className="max-xl:w-[120px] office-cards-hr" viewBox="0 0 192 2" fill="none">
                                            <path d="M0 1H192" stroke="#D9D9D9"/>
                                        </svg>
                                        <div className="flex items-center pt-[4px]">
                                            <a onClick={handleLocationSuccess} id={d.id} target="_blank" rel="noreferrer" href={d.mapLink} className="text-[#6495ED] md:text-base sm:text-sm  font-semibold office-card-text font-spartan underline">View on maps</a>
                                        <span className="ml-[2px]" >
                                            <CustomSVG iconName="diagonalArrow"/>
                                        </span>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            ))}
                        </Slider>
                </div>
            </div>

        </div>

        </>

)}