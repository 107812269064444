import React, { useState } from "react";
import Slider from "react-slick";
import { TEModal } from "tw-elements-react";
import CustomSVG from "./svg/CustomSvg";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <CustomSVG iconName="slickNextArrow" />
    </div>
  );
}
function NoArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <div className="hidden"></div>
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className,  onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <CustomSVG iconName="slickPrevArrow" />
    </div>
  );
}

export default function ImgGalleryPopup({ showImgModal, setShowImgModal, imglinkProps }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive:[
      {
        breakpoint: 1024,
        settings: {
          nextArrow: <NoArrow/>,
          prevArrow: <NoArrow/>,
        }
      },
    ],
    afterChange: (current) => setCurrentSlide(current),
  };

  return (
    <TEModal show={showImgModal} setShow={setShowImgModal} staticProperties className="open-bottomup-modal-animation flex flex-col items-center md:justify-center justify-end max-md:pb-[90px]">
      <div className="lg:container mx-auto xl:h-[620px] h-[570px] bg-white shadow max-lg:h-full max-lg:w-full max-lg:absolute max-lg:top-0 max-lg:bg-black">
        <div className="text-right !sticky !top-0 bg-[#D9D9D9] max-lg:bg-zinc-900">
            <button className="my-[20px]" onClick={() => setShowImgModal(false)}>
            <svg className="mr-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18 6L6 18M6 6L18 18" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            </button>
        </div>

        <div className="xl:mx-[300px] lg:mx-[230px] md:mx-[150px] sm:mx-[50px] mx-[0px] mt-[3em]">
          <div className="sm:-mt-[3em] max-sm:mt-[10em]">
          <div className="text-center text-black text-gray-600 mt-2 max-lg:text-white">
                {currentSlide + 1}/{imglinkProps.length}
          </div>
            <Slider {...settings}>
              {imglinkProps.map((url, index) => (
                <div className="justify-center !flex" key={index}>
                  <img className="class3 w-[600px] aspect-[5/4] object-cover h-auto" src={url.imgUrl} alt={`pic ${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </TEModal>
  );
}
