import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


    

const logosData = [
    "https://ik.imagekit.io/AcmeSpaces/logo123/1.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/2.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/3.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/4.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/5.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/6.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/7.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/8.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/9.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/1.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/2.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/3.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/4.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/5.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/6.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/7.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/8.png",
    "https://ik.imagekit.io/AcmeSpaces/logo123/9.png",
    // Add more logo URLs as needed
  ];

  const getAnchorPlacement = () => {
    if (window.innerWidth < 768) {
      return "bottom-bottom";
    } else {
      return "center-bottom";
    }
  }

export default function BrandPartners() {
    useEffect(() => {
        AOS.init();
        }, [])
    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 2000,
    //     autoplay: true,
    //     autoplaySpeed: 0,
    //     cssEase: "linear",
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     pauseOnHover: true,
    //     responsive: [
    //         {
    //           breakpoint: 1024,
    //           settings: {
    //             slidesToShow: 3,
    //             slidesToScroll: 1,
    //             infinite: true,
    //             dots: false,
    //             speed: 2000,
    //             autoplay: true,
    //             autoplaySpeed: 0,
    //             cssEase: "linear",
    //             pauseOnHover: true,
    //           }
    //         },
    //         {
    //             breakpoint: 640,
    //             settings: {
    //               slidesToShow: 1,
    //               slidesToScroll: 1,
    //               infinite: true,
    //               dots: false,
    //               speed: 5000,
    //               autoplay: true,
    //               autoplaySpeed: 0,
    //               cssEase: "linear",
    //               pauseOnHover: true,
    //             }
    //           }
    //     ]
    //   };

    const [logos, setLogos] = useState(logosData);
    
    useEffect(() => {
        // Clone logos to create a seamless loop
        setLogos([...logosData, ...logosData]);
    }, []);
    
    const handleMouseOver = () => {
        // Pause the animation on hover
        document.getElementById('carousel').style.animationPlayState = 'paused';
    };
    
    const handleMouseOut = () => {
        // Resume the animation when the mouse leaves
        document.getElementById('carousel').style.animationPlayState = 'running';
    };
    
    return(
        <>
        <div className="sm:container mx-auto ">
            <div  data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement={getAnchorPlacement()} className="flex items-center justify-center md:mt-[100px] md:mb-[70px] sm:mt-[55px] sm:mb-[40px] mt-[70px] mb-[10px]">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="458" height="2" viewBox="0 0 458 2" className="max-2xl:w-[300px] max-xl:w-[260px] max-lg:w-[120px] max-md:w-[110px] max-sm:hidden" fill="none">
                    <path d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1 2H458V0L1 0V2Z" fill="#D9D9D9"/>
                    </svg>
                </div>
                    <div className=" text-black text-5xl font-spartan font-semibold mx-[14px] max-md:text-[40px] max-sm:text-[28px]">Our Brand Partners</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="458" height="2" viewBox="0 0 458 2" className="max-2xl:w-[300px] max-xl:w-[260px] max-lg:w-[120px] max-md:w-[110px] max-sm:hidden" fill="none">
                    <path d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1 2H458V0L1 0V2Z" fill="#D9D9D9"/>
                    </svg>
                </div>
            </div>

        {/* <div className="xl:container brand-partners max-sm:w-[376px] max-xl:w-[990px] max-lg:w-[750px] max-md:w-[600px] mx-auto py-8">
            <Slider {...settings} className="mx-4">
            {logos.map((logo, index) => (
                <div key={index} className="!flex items-center justify-center">
                <img
                    src={logo}
                    alt={`logo-${index}`}
                    className="sm:max-h-16 max-h-[45px] max-w-full"
                />
                </div>
            ))}
            </Slider>
        </div> */}

        <div data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement={getAnchorPlacement()} className="xl:container brand-partners max-sm:w-[376px] max-xl:w-[990px] max-lg:w-[750px] max-md:w-[600px] mx-auto py-8 flex overflow-hidden">
            <div className="/*carousel-parent*/">
            <div 
                className="slider-loop items-center sm:gap-[150px] gap-[35px]"
                id="carousel"
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                >
              {logos.map((logo, index) => (
                    <img
                        key={index}
                        src={logo}
                        alt={`logo-${index}`}
                        className="carousel-logo"
                    />
                ))}
            </div>
            </div>
        </div>

        </div>

        </>

)}
