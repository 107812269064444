import { useForm } from "react-hook-form";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import axios from 'axios'; 
import React, { useState} from "react";
import NewsletterPopup from "../assets/ModalNewsletterPopup";


var rootUrl = process.env.REACT_APP_API_URL

const handleLocationSuccess = () => {
    // Push the event to the dataLayer
    window.dataLayer.push({
        event: 'mapsOpenSuccess',
        officeLocation: 'success'
    })}
    

const NewsletterSubmit = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

const [showModal, setShowModal] = useState(false);
            
const onSubmit = async (data) => {
console.log("Submitting form with data:", data);
console.log("rootUrl:", rootUrl);

try {
    const submitButton = document.getElementById('subscribeBtn');
    submitButton.classList.add('hidden');

    const submitButtonShow = document.getElementById('subscribeBtnShow');
    submitButtonShow.classList.add('!flex');

    const response = await axios.post(`${rootUrl}/api/v1/addNewsletterEmail`, {
    email: data.email,
});
    console.log("Response from server:", response);
    if (response.status === 201) {
        reset();
        setShowModal(true);
        const submitButtonShow = document.getElementById('subscribeBtnShow');
        submitButtonShow.classList.add('hidden');
        submitButtonShow.classList.remove('!flex');


        const submitButton = document.getElementById('subscribeBtn');
        submitButton.classList.add('block');
        submitButton.classList.remove('hidden');


    console.log("Form sent successfully!");
    // GTM event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "NewsletterSubmission",
        newsletterStatus: "success",
    });
    } else {
    console.error('Error submitting form:', response.data);
    window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "NewsletterSubmission",
                    newsletterStatus: "error",
                });
    }
} catch (error) {
    console.error('Error submitting form:', error.message);
    window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "NewsletterSubmission",
                newsletterStatus: "error",
            });
}
};

return (
        <footer className="bg-[#13293D] text-center text-white">

            <div>
                <div className='pt-[55px] flex justify-center'>
                    <img className="max-lg:w-[400px] max-sm:w-[250px]" src='https://ik.imagekit.io/AcmeSpaces/logo/logoFooter2.png' alt='footer-logo'/></div>
            </div>


                

            <div className="container px-6  pt-[30px] mx-auto">
                {/* <!-- Social media icons container --> */}
                <div className="lg:mb-6 mb-4 flex justify-center sm:gap-[46px] gap-[32px] w-full">
                    
                        <a
                            target="_blank" rel="noreferrer"
                            href="https://www.facebook.com/acmespaces1/"
                            type="button"
                            className="m-1 flex items-center justify-end"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="" width="33" height="33" viewBox="0 0 33 33" fill="none">
                            <path d="M32.4697 16.2348C32.4697 7.27321 25.1965 0 16.2348 0C7.2732 0 0 7.27321 0 16.2348C0 24.0925 5.58478 30.6351 12.9879 32.145V21.1053H9.7409V16.2348H12.9879V12.1761C12.9879 9.0428 15.5367 6.49393 18.6701 6.49393H22.7288V11.3644H19.4818C18.5889 11.3644 17.8583 12.095 17.8583 12.9879V16.2348H22.7288V21.1053H17.8583V32.3885C26.0569 31.5768 32.4697 24.6607 32.4697 16.2348Z" fill="#EDF5F7"/>
                            </svg>
                        </a>
                    

                        <a
                            target="_blank" rel="noreferrer" 
                            href="https://www.instagram.com/acmespaces"
                            type="button"
                            className="m-1 flex items-center"

                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="" width="34" height="33" viewBox="0 0 34 33" fill="none">
                            <path d="M10.217 0H23.8542C29.0494 0 33.2704 4.22106 33.2704 9.4162V23.0535C33.2704 25.5508 32.2784 27.9458 30.5125 29.7117C28.7466 31.4776 26.3516 32.4697 23.8542 32.4697H10.217C5.02184 32.4697 0.800781 28.2486 0.800781 23.0535V9.4162C0.800781 6.91887 1.79284 4.52382 3.55872 2.75794C5.3246 0.992061 7.71965 0 10.217 0ZM9.89229 3.24697C8.34222 3.24697 6.85564 3.86273 5.75957 4.95879C4.66351 6.05486 4.04775 7.54144 4.04775 9.09151V23.3782C4.04775 26.6089 6.66155 29.2227 9.89229 29.2227H24.1789C25.729 29.2227 27.2156 28.6069 28.3117 27.5109C29.4077 26.4148 30.0235 24.9282 30.0235 23.3782V9.09151C30.0235 5.86077 27.4097 3.24697 24.1789 3.24697H9.89229ZM25.5589 5.68219C26.0971 5.68219 26.6133 5.896 26.9939 6.27657C27.3744 6.65715 27.5883 7.17333 27.5883 7.71154C27.5883 8.24976 27.3744 8.76594 26.9939 9.14651C26.6133 9.52709 26.0971 9.7409 25.5589 9.7409C25.0207 9.7409 24.5045 9.52709 24.1239 9.14651C23.7433 8.76594 23.5295 8.24976 23.5295 7.71154C23.5295 7.17333 23.7433 6.65715 24.1239 6.27657C24.5045 5.896 25.0207 5.68219 25.5589 5.68219ZM17.0356 8.11742C19.1885 8.11742 21.2532 8.97264 22.7755 10.495C24.2978 12.0173 25.153 14.082 25.153 16.2348C25.153 18.3877 24.2978 20.4524 22.7755 21.9747C21.2532 23.497 19.1885 24.3522 17.0356 24.3522C14.8827 24.3522 12.818 23.497 11.2957 21.9747C9.77342 20.4524 8.9182 18.3877 8.9182 16.2348C8.9182 14.082 9.77342 12.0173 11.2957 10.495C12.818 8.97264 14.8827 8.11742 17.0356 8.11742ZM17.0356 11.3644C15.7439 11.3644 14.5051 11.8775 13.5917 12.7909C12.6783 13.7043 12.1652 14.9431 12.1652 16.2348C12.1652 17.5266 12.6783 18.7654 13.5917 19.6788C14.5051 20.5921 15.7439 21.1053 17.0356 21.1053C18.3273 21.1053 19.5662 20.5921 20.4795 19.6788C21.3929 18.7654 21.9061 17.5266 21.9061 16.2348C21.9061 14.9431 21.3929 13.7043 20.4795 12.7909C19.5662 11.8775 18.3273 11.3644 17.0356 11.3644Z" fill="#EDF5F7"/>
                            </svg>
                        </a>                    
                    
                        <a
                            target="_blank" rel="noreferrer" 
                            href="https://www.linkedin.com/company/acmespaces/"
                            type="button"
                            className="m-1 flex items-center"

                        >
                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29.8719 0.00291198H2.78019C2.43099 -0.0134433 2.082 0.0392146 1.75318 0.157902C1.42437 0.276589 1.1222 0.458952 0.863947 0.69454C0.605715 0.930149 0.396472 1.21438 0.248228 1.53093C0.0999855 1.84751 0.0156259 2.19019 0 2.53941V29.6899C0.0204557 30.4207 0.319944 31.1161 0.836957 31.6329C1.35397 32.15 2.04931 32.4495 2.78019 32.47H29.8719C30.5823 32.4383 31.252 32.1295 31.7377 31.6098C32.2233 31.0901 32.4859 30.401 32.4693 29.6899V2.53941C32.4751 2.19951 32.4114 1.86203 32.2821 1.54763C32.1529 1.23325 31.9607 0.948574 31.7174 0.711058C31.4742 0.473563 31.1851 0.288257 30.8679 0.166485C30.5505 0.0447138 30.2116 -0.0109474 29.8719 0.00291198ZM10.1467 27.0724H5.41832V12.6042H10.1467V27.0724ZM7.8941 10.4127C7.56415 10.4242 7.23539 10.3673 6.92846 10.2457C6.62152 10.124 6.34307 9.94025 6.11058 9.70587C5.87808 9.47148 5.69657 9.19154 5.57744 8.88365C5.4583 8.57577 5.40414 8.24657 5.41832 7.91676C5.40373 7.58335 5.45899 7.2506 5.58057 6.93984C5.70215 6.62905 5.88733 6.3471 6.12426 6.11212C6.36121 5.87709 6.64463 5.6942 6.95641 5.57516C7.2682 5.45611 7.6014 5.40355 7.93469 5.42084C8.26465 5.40935 8.59341 5.46619 8.90033 5.58782C9.20727 5.70946 9.48572 5.89325 9.71821 6.12764C9.95072 6.36202 10.1322 6.64195 10.2513 6.94985C10.3705 7.25772 10.4247 7.58693 10.4105 7.91676C10.4251 8.25014 10.3698 8.5829 10.2482 8.89365C10.1266 9.20445 9.94146 9.48639 9.70453 9.7214C9.46758 9.9564 9.18416 10.1393 8.87239 10.2583C8.56059 10.3774 8.22739 10.4299 7.8941 10.4127ZM27.0713 27.0724H22.3226V19.1585C22.3226 17.2714 21.6531 15.9727 19.9686 15.9727C19.4455 15.9771 18.9363 16.1433 18.5114 16.4485C18.0862 16.7537 17.766 17.1829 17.5943 17.6772C17.4624 18.0477 17.4072 18.4412 17.432 18.8338V27.0724H12.7645V12.6042H17.432V14.6334C17.847 13.8711 18.4645 13.2384 19.2166 12.8049C19.9686 12.3717 20.8259 12.1547 21.6937 12.1781C24.7578 12.1781 27.0713 14.2073 27.0713 18.5295V27.0724Z" fill="white"/>
                            </svg>

                        </a>
                    
                </div>

                {/* <!-- Newsletter sign-up form --> */}
                <div>
                    <div className="md:mx-auto pb-[12px]">
                            <span className='text-justify text-[#6495ED] lg:text-[45px] sm:text-[36px] text-[22px] max-sm:whitespace-nowrap font-bold font-spartan'>Sign Up To Our Newsletter</span>
                    </div>
                    
                    <div className='flex justify-center  mb-[50px]'>
                        <form className='flex items-center'

                        onSubmit={handleSubmit(onSubmit)}
                        noValidate>
                            <div className="footer-subscribe-input lg:w-[872px] sm:w-[600px] w-[380px] sm:h-[58px] h-[44px] bg-white rounded-[38px]">
                                <input className='footer-subscribe-input bg-white lg:w-[872px] sm:w-[600px] w-[380px] sm:h-[58px] h-[44px] rounded-[38px] text-[#000] font-montserrat text-xl font-normal
                                !outline-none pl-[2rem] placeholder:text-neutral-400 sm:placeholder:text-xl placeholder:text-[14px] placeholder:font-normal placeholder:font-montserrat' placeholder="Enter Your Email" 
                                type="email"
                                  name="email"
                                  {...register("email", {
                                    required: true,
                                    pattern:
                                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                  })}
                            />
                                {errors.email && (
                                  <span className="flex font-montserrat text-[yellow] ml-6">
                                    Please enter a valid email address*
                                  </span>
                                )}
                            </div>
                            <div className='footer-subscribe-btn absolute lg:ml-[44.4em] sm:ml-[27.2em] ml-[17.2em] font-2'>
                                <button id="subscribeBtn" className='font-semibold sm:w-[153.99px] w-[100px] sm:h-[43.61px] h-[36px] bg-blue-400 rounded-[27px] max-sm:text-[14px]'>Subscribe</button>

                                <button id="subscribeBtnShow" className='font-semibold hidden items-center justify-center sm:w-[153.99px] w-[100px] sm:h-[43.61px] h-[36px] bg-blue-400 rounded-[27px] max-sm:text-[14px]'>
                                Subscribe
                                <svg class="animate-spin -mr-1 sm:ml-3 ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                </button>
                            </div>

                        </form>
                            
                    </div>
                </div>

                

            </div>


            <div className="flex justify-center mx-auto max-sm:w-[384px] footer-hr">
                <svg xmlns="http://www.w3.org/2000/svg" width="1624" height="2" viewBox="0 0 1624 2" fill="none">
                    <path d="M1 1H1623" stroke="#999999" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </div>

            {/* <!-- Copyright section --> */}

            <div className="flex max-lg:justify-center lg:justify-between mx-auto container lg:py-[30px] py-[14px]">
                <div
                    className="flex text-center text-[#EDF5F7] text-base font-medium font-spartan">
                    @2024 all rights reserved.
                    <span className="px-[4px]"> | </span>
                    <a href="/privacy-policy" className="underline">Privacy Policy</a>
                </div>


                <div className="max-lg:hidden gap-[35px] flex">
                    <div className="flex items-center gap-[12px]">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M20.79 15.1311C19.2867 15.1311 17.8322 14.8867 16.4756 14.4467C16.263 14.3746 16.0345 14.3639 15.8161 14.4158C15.5978 14.4677 15.3985 14.58 15.2411 14.74L13.3222 17.1478C9.86333 15.4978 6.62444 12.3811 4.90111 8.8L7.28444 6.77111C7.61444 6.42889 7.71222 5.95222 7.57778 5.52444C7.12556 4.16778 6.89333 2.71333 6.89333 1.21C6.89333 0.55 6.34333 0 5.68333 0H1.45444C0.794445 0 0 0.293333 0 1.21C0 12.5644 9.44778 22 20.79 22C21.6578 22 22 21.23 22 20.5578V16.3411C22 15.6811 21.45 15.1311 20.79 15.1311Z" fill="#EDF5F7"/>
                        </svg>
                    </span>
                    <a target="_blank" rel="noreferrer" href="tel:+919910109700" className="text-[#EDF5F7] lg:text-base text-sm font-normal font-spartan underline">
                    +91-99101 09700
                    </a>
                    </div>

                    <div className="flex items-center gap-[12px]">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17" fill="none">
                            <path d="M20.5417 17H2.125C0.949167 17 0 16.0508 0 14.875V2.125C0 0.949167 0.949167 0 2.125 0H20.5417C21.7175 0 22.6667 0.949167 22.6667 2.125V14.875C22.6667 16.0508 21.7175 17 20.5417 17ZM2.125 1.41667C1.72833 1.41667 1.41667 1.72833 1.41667 2.125V14.875C1.41667 15.2717 1.72833 15.5833 2.125 15.5833H20.5417C20.9383 15.5833 21.25 15.2717 21.25 14.875V2.125C21.25 1.72833 20.9383 1.41667 20.5417 1.41667H2.125Z" fill="#EDF5F7"/>
                            <path d="M11.3342 11.2765C10.3425 11.2765 9.43583 10.8799 8.75583 10.1574L1.31833 2.25238C1.04916 1.96905 1.06333 1.51572 1.34666 1.24655C1.62999 0.977383 2.08333 0.99155 2.35249 1.27488L9.78999 9.17988C10.5975 10.0441 12.0708 10.0441 12.8783 9.17988L20.3158 1.28905C20.585 1.00572 21.0383 0.99155 21.3217 1.26072C21.605 1.52988 21.6192 1.98322 21.35 2.26655L13.9125 10.1715C13.2325 10.8941 12.3258 11.2907 11.3342 11.2907V11.2765Z" fill="#EDF5F7"/>
                        </svg>
                    </span>
                    <a target="_blank" rel="noreferrer" href="mailto:sales@acmespaces.com" className="text-[#EDF5F7] lg:text-base text-sm font-normal font-spartan underline">
                    sales@acmespaces.com
                    </a>
                    </div>

                    <div className="flex items-center gap-[12px]">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <path d="M10.5 1.75098C12.5886 1.75098 14.5916 2.58066 16.0685 4.05751C17.5453 5.53436 18.375 7.5374 18.375 9.62598C18.375 12.3157 16.9085 14.5172 15.3633 16.0966C14.5911 16.877 13.7487 17.5847 12.8468 18.2106L12.474 18.4644L12.299 18.5807L11.9691 18.7907L11.6751 18.9701L11.3111 19.1819C11.0639 19.3225 10.7844 19.3965 10.5 19.3965C10.2156 19.3965 9.93606 19.3225 9.68888 19.1819L9.32487 18.9701L8.86987 18.6901L8.70187 18.5807L8.34312 18.3419C7.37011 17.6833 6.46368 16.9313 5.63675 16.0966C4.0915 14.5164 2.625 12.3157 2.625 9.62598C2.625 7.5374 3.45468 5.53436 4.93153 4.05751C6.40838 2.58066 8.41142 1.75098 10.5 1.75098ZM10.5 7.00098C10.1553 7.00098 9.81394 7.06887 9.49546 7.20079C9.17698 7.33271 8.8876 7.52607 8.64384 7.76982C8.40009 8.01357 8.20673 8.30295 8.07482 8.62143C7.9429 8.93991 7.875 9.28126 7.875 9.62598C7.875 9.9707 7.9429 10.312 8.07482 10.6305C8.20673 10.949 8.40009 11.2384 8.64384 11.4821C8.8876 11.7259 9.17698 11.9192 9.49546 12.0512C9.81394 12.1831 10.1553 12.251 10.5 12.251C11.1962 12.251 11.8639 11.9744 12.3562 11.4821C12.8484 10.9898 13.125 10.3222 13.125 9.62598C13.125 8.92978 12.8484 8.2621 12.3562 7.76982C11.8639 7.27754 11.1962 7.00098 10.5 7.00098Z" fill="#DEDDEB"/>
                        </svg>
                    </span>
                    <a id="WS_Office_footer" onClick={handleLocationSuccess} target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/aijdNdgpSzWqxm4o6" className="text-[#EDF5F7] lg:text-base text-sm font-normal font-spartan underline">
                    OMAXE World Street, Faridabad
                    </a>
                    </div>
                </div>
            </div>
            <NewsletterPopup 
                showModal ={showModal}
                setShowModal={setShowModal} 
            />
        </footer>
        
    );
};


export default NewsletterSubmit;
